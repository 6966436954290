import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AccessoryPictureApi, BranchOfficeApi,
  ClientApi,
  InspectionApi, ProcedureTypeApi,
  SettingApi,
  VehiclePictureApi,
} from '../../../shared/sdk';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { InspectionSendingDataComponent } from
    './inspection-sending-data/inspection-sending-data.component';
import { MockService } from '../../../shared/services/mock.service';
import { AlertService } from '../../../shared/services/alert.service';
import { AuthGuard } from '../../../shared';

@Component({
  selector: 'app-user-profile-edit',
  templateUrl: './inspection-generator.component.html',
  styleUrls: ['./inspection-generator.component.scss'],
  // animations: [routerTransition()]
})
export class InspectionGeneratorComponent implements OnInit {

  myForm: FormGroup;
  user: any; // Photographer
  id: string;
  submitAttempt: boolean;
  loadingPage: boolean;
  coverageTypes;
  selectedCoverageTypes;
  procedureTypes;
  incidentPictures = [
    {
      name: 'vehicleIdFront',
      type: 'document',
      displayName: 'Cedula vehicular - Frente',
    },
    {
      name: 'vehicleIdBack',
      type: 'document',
      displayName: 'Cedula Vehicular - Dorso',
    },
    {
      name: 'frontRight',
      type: 'vehicle',
      displayName: '.',
    },
    {
      name: 'frontLeft',
      type: 'vehicle',
      displayName: '.',
    },
    {
      name: 'rearLeft',
      type: 'vehicle',
      displayName: '.',
    },
    {
      name: 'rearRight',
      type: 'vehicle',
      displayName: '.',
    },
    {
      name: 'vehicleInside',
      type: 'vehicle',
      displayName: '.',
    },
  ];
  sellers;
  branchOffices;
  public constructor (
    private formBuilder: FormBuilder,
    private clientApi:  ClientApi,
    private settingApi:  SettingApi,
    private toastr: ToastrService,
    private procedureTypeApi: ProcedureTypeApi,
    private accesoryPictureApi: AccessoryPictureApi,
    private vehiclePicturesApi: VehiclePictureApi,
    private dialog: MatDialog,
    private alertService: AlertService,
    private mockService:MockService,
    private authGuard:AuthGuard,
    private inspectionApi: InspectionApi,
    private branchOfficeApi: BranchOfficeApi,
  ) {}
  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.procedureTypeApi.find().subscribe((data) => {
      this.procedureTypes = data;
    });
    this.loadingPage = true;
    this.myForm = this.formBuilder.group({
      name: ['', Validators.compose(
        [Validators.maxLength(30),
          Validators.required])],
      lastname: ['', Validators.compose(
        [Validators.maxLength(30),
          Validators.required])],
      vehicleType:  ['',  Validators.required],
      requiredBy:  [null,  Validators.required],
      branchOfficeId:  [null,  Validators.required],
      licencePlate:  ['', [Validators.pattern('^[a-zA-Z]{2}[0-9]{3}[a-zA-Z]{2}$|^[a-zA-Z]{3}[0-9]{3}$'), Validators.required]],
    });
    this.loadingPage = false;
    this.onFormChanges();
    this.clientApi.searchSalesmen().subscribe((sellers) => {
      this.sellers = sellers;
    });
    this.getBranchOffices();
  }

  copyToClipboard(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.success(
      'Api-Key copiada al portapapeles', 'Copiado',
      { positionClass: 'toast-bottom-right',
      });
  }
  onFormChanges(): void {}
  generatePreviousInspection() {
    const now = new Date();
    const inspectionData = {
      transactionId : now.getTime().toString(),
      agentCode: '123',
      licencePlate: this.myForm.value.licencePlate,
      tipoDoc: 'DNI',
      branchOfficeId:  this.myForm.value.branchOfficeId,
      requiredBy: this.myForm.value.requiredBy,
      numeroDoc:  this.myForm.value.licencePlate,
      procedureType: 'inspection',
      vehicleType: this.myForm.value.vehicleType,
      clientName: `${this.myForm.value.name} ${this.myForm.value.lastname}`,
      pictures: this.incidentPictures,
      inspectionForm: [],
    };
    this.generateInspection(inspectionData);
  }
  generateInspection(inspectionData) {
    this.inspectionApi.createInspection({}, inspectionData).subscribe((res:any) => {
      const dialogRef = this.dialog.open(InspectionSendingDataComponent, {
        data: { link: res.link },
        height: '300px',
        width: '600px',
      });
    });
  }
  resetSelection() {
    this.myForm.get('coverageType').reset();
  }
  getBranchOffices() {
    const filter = {
      where:{},
    };
    if (!this.authGuard.hasRoles(['superadmin'])) {
      filter.where['users'] =  { inq: [this.user.id] } ;
    }
    filter.where['isActive'] =  { eq: true } ;
    this.branchOfficeApi.find(filter).subscribe((data:any) => {
      this.branchOffices = data;
    },                                          (error) => {
      this.alertService.generalError();
    });
  }
}
