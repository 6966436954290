import { BrowserModule, HAMMER_LOADER } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/user-management/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './components/layout/header/header.component';
import { SidebarComponent } from './components/layout/sidebar/sidebar.component';
import { LayoutComponent } from './components/layout/layout.component';
import { InspectionsListComponent }
from './components/inspecitons/inspections-list/inspections-list.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InspectionDetailComponent } from
    './components/inspecitons/inspection-detail/inspection-detail.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { UserProfileEditComponent } from
    './components/user-management/user-profile-edit/user-profile-edit.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { SDKBrowserModule } from './shared/sdk/';
import { AuthGuard } from './shared';
import { Ng5SliderModule } from 'ng5-slider';
import { InspectionMoreInformationComponent }
from './components/inspecitons/inspection-more-information/inspection-more-information.component';
import { AnalysisComponent } from './components/analysis/analysis.component';
import { ChartsModule } from 'ng2-charts';
import { ToastrModule } from 'ngx-toastr';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { InspectionDetailOnePageComponent } from
    './components/inspecitons/inspection-detail-one-page/inspection-detail-one-page.component';
import { HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';
import { AuthInterceptorService } from './shared/services/auth-interceptor.service';
import { UserListComponent } from './components/user-management/user-list/user-list.component';
import { InspectionService } from './shared/services/inspection.service';
import { PreviewReportComponent }
from './components/analysis/preview-report/preview-report.component';
import { RelatedInspectionsModalComponent } from
// tslint:disable-next-line:max-line-length
'./components/inspecitons/inspection-detail/related-inspections-modal/related-inspections-modal.component';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatChipsModule } from '@angular/material/chips';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { GoogleMapsModule } from '@angular/google-maps';
import { GoogleAnalyticsService } from './shared/services/google-analytics.services';
import { CustomerDataFormComponent } from './components/inspecitons/inspection-detail/customer-data-form/customer-data-form.component';
import { VehicleDataFormComponent } from './components/inspecitons/inspection-detail/vehicle-data-form/vehicle-data-form.component';
import { TechnicalFormComponent } from './components/inspecitons/inspection-detail/technical-form/technical-form.component';
import {
  InspectionSendingDataComponent,
} from './components/inspecitons/inspection-generator/inspection-sending-data/inspection-sending-data.component';
import { InspectionGeneratorComponent } from './components/inspecitons/inspection-generator/inspection-generator.component';
import { DebounceClickDirective } from './shared/directives/debounce.directive';
import { FormatNumberPipe } from './shared/pipes/format-number.pipe';
import { AutocompleteDropdownComponent } from './shared/components/autocomplete-dropdown/autocomplete-dropdown.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BranchOfficesComponent } from './components/branch-offices/branch-offices.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    SidebarComponent,
    LayoutComponent,
    InspectionsListComponent,
    InspectionDetailComponent,
    InspectionDetailOnePageComponent,
    UserProfileEditComponent,
    InspectionMoreInformationComponent,
    AnalysisComponent,
    UserListComponent,
    RelatedInspectionsModalComponent,
    PreviewReportComponent,
    CustomerDataFormComponent,
    VehicleDataFormComponent,
    TechnicalFormComponent,
    InspectionSendingDataComponent,
    InspectionGeneratorComponent,
    DebounceClickDirective,
    FormatNumberPipe,
    AutocompleteDropdownComponent,
    BranchOfficesComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatMenuModule,
    MatIconModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatSelectModule,
    MatSliderModule,
    MatChipsModule,
    MatCardModule,
    MatIconModule,
    MatProgressBarModule,
    MatButtonModule,
    LazyLoadImageModule,
    NgbModalModule,
    SDKBrowserModule.forRoot(),
    Ng5SliderModule,
    ChartsModule,
    ToastrModule.forRoot(),
    MatDialogModule,
    NgxImageZoomModule,
    GoogleMapsModule,
    HttpClientJsonpModule,
    NgSelectModule,
    MatSlideToggleModule,
  ],
  providers: [
    CurrencyPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    InspectionService,
    AuthGuard,
    {provide: HAMMER_LOADER,
      useValue: () => new Promise(() => {})},
    { provide: MAT_DATE_LOCALE, useValue: 'es-AR' },
    DatePipe,
    GoogleAnalyticsService,
  ],
  entryComponents: [
    RelatedInspectionsModalComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
