import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import sweetalert2 from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { InspectionService } from '../../../shared/services/inspection.service';
import { RelatedInspectionsModalComponent }
  from './related-inspections-modal/related-inspections-modal.component';
import { HistoricalInspectionApi } from '../../../shared/sdk';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { GoogleMap } from '@angular/google-maps';
import { AlertService } from '../../../shared/services/alert.service';
import { NgForm } from '@angular/forms';
import { AuthGuard } from '../../../shared';
import { VehicleDataFormComponent } from './vehicle-data-form/vehicle-data-form.component';
import { CustomerDataFormComponent } from './customer-data-form/customer-data-form.component';

const DEFAULT_ZOOM = 15;

@Component({
  selector: 'app-inspection-detail',
  templateUrl: './inspection-detail.component.html',
  styleUrls: ['./inspection-detail.component.scss'],
})
export class InspectionDetailComponent implements OnInit {

  inspection: any;
  vehicleType;
  bounds: any = false;
  pictures = [];
  inspectionId: string;
  browserSupportsExifRotation;
  relatedInspections;
  disableRetry = false;
  selectedTab = 'form';
  markerOptions: google.maps.MarkerOptions = { draggable: false };
  technicalModules;
  @ViewChild('form') form: NgForm;
  @ViewChild('vehicleDataForm') vehicleDataForm:VehicleDataFormComponent;
  @ViewChild('customerDataForm') customerDataForm:CustomerDataFormComponent;
  @ViewChild('googleMapRef') googleMaps: GoogleMap;
  zoom = DEFAULT_ZOOM - 1; // Hack to map zoom works fine
  estimate = {
    value: '',
    observation: '',
    noAdmitted: false,
  };
  readonly: boolean = true;
  notApplicableElementes: any[];
  formattedAmount;
  amount ;
  states = [{ displayName: 'Peritado', name:'evaluated' },
    { displayName:'Con operación', name:'operation' },
    { displayName: 'ingresado', name:'entered' }];
  isSuperadmin: boolean;
  isAdministrative: boolean;
  isAppraiser: boolean;
  vehicleModule: any;
  customerModule: any;
  expandedTechnicalModules: boolean;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    public dialog: MatDialog,
    private inspectionService: InspectionService,
    private historicalInspectionApi: HistoricalInspectionApi,
    private toastr: ToastrService,
    private alertService: AlertService,
    private authGuard:AuthGuard,
  ) {
    this.inspectionId = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user'));
    this.route.params.subscribe(
      (params) => {
        const id = params['id'];
        this.inspectionId = id;
        this.getInspection();
        this.isSuperadmin = this.hasRoles(['superadmin']);
        this.isAdministrative = this.hasRoles(['administrative']);
        this.isAppraiser = this.hasRoles(['appraiser']);
      },
    );
    this.checkScrollDistance();
  }

  getInspection() {
    this.inspectionService.getInspection(this.inspectionId).subscribe(
      (inspection) => {
        this.inspection = inspection;
        this.sortEstimates();
        if (this.inspection.sourceApp && this.inspection.sourceApp === 'web-app') {
          this.selectedTab = 'pictures';
        }
        this.technicalModules = this.getTechnicalModules();
        this.vehicleModule = this.getModule('vehicle');
        this.customerModule = this.getModule('customer');
        this.setNotApplicableElements();
        if (this.technicalModules.length > 0) {
          this.technicalModules[0].expand = true;
        }
        this.inspectionService.getRelatedIspections(inspection).subscribe((relatedInspections) => {
          this.relatedInspections = relatedInspections;
        });
      },
      (err: any) => {
        sweetalert2.fire({
          title: 'Ups! Algo salio mal',
          text: 'Ocurrió un error al acceder a los datos.',
          type: 'error',
          confirmButtonText: 'Salir',
          buttonsStyling: false,
        });
      },
    );
  }

  openModalRelated() {
    const options = {
      data: { relatedInspections: this.relatedInspections },
    };
    const dialogRef = this.dialog.open(RelatedInspectionsModalComponent, options);
    dialogRef.afterClosed().subscribe((value) => {
    });
  }

  getModule(name) {
    return this.inspection.inspectionForm.data.find((data) => {
      return data.name === name;
    });
  }

  getTechnicalModules() {
    return this.inspection.inspectionForm.data.filter((data) => {
      return data.type === 'technical';
    });
  }

  setAccordionStatus(module) {
    module.expand = !module.expand;
  }

  createEstimate() {
    this.historicalInspectionApi
      .estimate(this.inspection.id, {
        value: this.estimate.value, observation: this.estimate.observation,
      })
      .subscribe((data) => {
        this.estimate.value = '';
        this.formattedAmount = '';
        this.estimate.observation = null;
        this.estimate.noAdmitted = false;
        this.toastr.success(
          'La cotización se generó con éxito',
          'Cotización',
          {
            positionClass: 'toast-bottom-right',
          });
        this.inspection.estimates = data.estimates;
        this.sortEstimates();
      },         (error) => {
        this.alertService.generalError();
      });
  }

  testRegex(event) {
    if (!`${this.estimate.value}${event.key}`.match('^[0-9.]*$') && event.keyCode !== 8) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
  }
  enableEdition() {
    this.readonly = false;
    this.setExpand(true);
  }
  closeEdition() {
    this.readonly = true;
    this.setExpand(false);
    this.technicalModules[0].expand = true;
  }

  setExpand(value, partial = false) {
    this.technicalModules.forEach((module) => {
      module.expand = value;
      if (!partial) {
        module.requiredSubmodules.forEach((submodule) => {
          submodule.expand = value;
        });
      }
    });
    this.expandedTechnicalModules = !this.expandedTechnicalModules;
  }
  deleteExpandAttr() {
    this.technicalModules.forEach((module) => {
      delete module.expand ;
      module.requiredSubmodules.forEach((submodule) => {
        delete submodule.expand;
      });
    });
  }
  updateInspection() {
    sweetalert2.fire({
      title: 'Modificar Inspección',
      text: '¿Está seguro que desea modificar la Inspección?',
      type: 'info',
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si, Modificar',
      customClass: {
        cancelButton: 'btn btn-ligth mx-1',
        confirmButton: 'btn text-white btn-primary mx-1',
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        this.vehicleDataForm.save();
        this.customerDataForm.save();
        this.deleteExpandAttr();
        this.historicalInspectionApi
          .patchAttributes(this.inspection.id, {
            inspectionForm: this.inspection.inspectionForm })
          .subscribe(async(inspection) => {
            this.inspection = inspection;
            this.sortEstimates();
            this.closeEdition();
          },         (error) => {
            this.alertService.generalError();
          });
      }else {
        this.closeEdition();
      }

    });
  }
  setNotApplicableElements() {
    const array = [];
    this.technicalModules.forEach((module) => {
      module.requiredSubmodules.forEach((element) => {
        if (element.notApplicable) {
          array.push(element);
        }
      });
    });
    this.notApplicableElementes = array;
  }
  validateTechnicalForm() {
    let isCompleted = true;
    this.notApplicableElementes.find((element) => {
      if (!element.notApplicable && !element.value) {
        isCompleted = false;
      }
    });
    if (isCompleted &&
      this.customerDataForm.isCompletedForm() &&
      this.vehicleDataForm.isCompletedForm()) {
      return true;
    }
    return false;
  }
  sortEstimates() {
    this.inspection?.
    estimates?.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  }

  setNotAdmitted() {
    if (this.estimate.noAdmitted) {
      this.estimate.value = '0';
      this.formattedAmount = '0';
    }else {
      this.estimate.value = '';
      this.formattedAmount = '';
    }
  }
  formatCurrency() {
    const formattedAmount = Number(this.formattedAmount.replaceAll('.', ''));
    this.estimate.value = formattedAmount.toString();
    this.formattedAmount = formattedAmount.toLocaleString('es-AR');
  }
  hasRoles(roles): boolean {
    return this.authGuard.hasRoles(roles);
  }
  changeState(state) {
    let pass = true;
    if (!this.hasRoles(['superadmin']) && state.name === 'entered') {
      pass = false;
    }
    if (this.hasRoles(['administrative']) &&
      this.inspection.status === 'entered' &&
      (state.name === 'operation' || state.name === 'evaluated')) {
      pass = false;
    }
    if (pass) {
      sweetalert2.fire({
        title: 'Modificar el estado de la compra',
        text: `¿Está seguro que desea modificar el estado de la compra a ${state.displayName}?`,
        type: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Si, Modificar',
        customClass: {
          cancelButton: 'btn btn-ligth mx-1',
          confirmButton: 'btn text-white btn-primary mx-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.historicalInspectionApi
            .patchAttributes(this.inspection.id, {
              status: state.name,
            })
            .subscribe((inspection) => {
              this.inspection = inspection;
              this.sortEstimates();
            },         (error) => {
              this.alertService.generalError();
            });
        }
      });
    }

  }

  resetAttr() {
    this.selectedTab = 'form';
  }

  goToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  checkScrollDistance() {
    const myID = document.getElementById('toTop');
    myID.className = 'd-none';

    const myScrollFunc = () => {
      const y = window.scrollY;
      if (y >= 170) {
        myID.className = '';
      } else {
        myID.className = 'd-none';
      }
    };

    window.addEventListener('scroll', myScrollFunc);
  }
}
