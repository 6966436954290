/* tslint:disable */

declare var Object: any;
export interface BranchOfficeInterface {
  "name": string;
  "creationDate"?: Date;
  "address": string;
  "city": string;
  "phoneNumber": string;
  "isActive"?: boolean;
  "users"?: any;
  "usersAsMain"?: any;
  "id"?: any;
}

export class BranchOffice implements BranchOfficeInterface {
  "name": string;
  "creationDate": Date;
  "address": string;
  "city": string;
  "phoneNumber": string;
  "isActive": boolean;
  "users": any;
  "usersAsMain": any;
  "id": any;
  constructor(data?: BranchOfficeInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `BranchOffice`.
   */
  public static getModelName() {
    return "BranchOffice";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of BranchOffice for dynamic purposes.
  **/
  public static factory(data: BranchOfficeInterface): BranchOffice{
    return new BranchOffice(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'BranchOffice',
      plural: 'BranchOffices',
      path: 'BranchOffices',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "creationDate": {
          name: 'creationDate',
          type: 'Date',
          default: new Date(0)
        },
        "address": {
          name: 'address',
          type: 'string'
        },
        "city": {
          name: 'city',
          type: 'string'
        },
        "phoneNumber": {
          name: 'phoneNumber',
          type: 'string'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "users": {
          name: 'users',
          type: 'any',
          default: <any>null
        },
        "usersAsMain": {
          name: 'usersAsMain',
          type: 'any',
          default: <any>null
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
      }
    }
  }
}
