import { Component, ContentChild, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { noop } from 'rxjs';

@Component({
  selector: 'app-autocomplete-dropdown',
  templateUrl: './autocomplete-dropdown.component.html',
  styleUrls: ['./autocomplete-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteDropdownComponent),
      multi: true,
    },
  ],
})
export class AutocompleteDropdownComponent implements ControlValueAccessor, OnInit{
  @Input() label;
  @Input() disabled = false;
  @Input() placeholder = '';
  @Input() required = false;
  @Input() readonly = false;
  @Input() mode:string;
  @Input() strictSearch = false;
  @Input() appearance = 'outline';
  @Input() model;
  @Output() focus = new EventEmitter();
  public fucused = false;
  @Output() focusout = new EventEmitter();
  @Output() blur = new EventEmitter();
  @Output() click = new EventEmitter();
  @ContentChild(NgControl, { static: false }) public control: any;
  showDropdown: boolean;
  filteredData: any;
  @Input() array: any;
  constructor() { }

  ngOnInit(): void {
  }
  /**
   * @description The internal data model
   */
  private innerValue: any = '';

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  /**
   * @description get accessor
   */
  get value(): any {
    return this.innerValue;
  }

  /**
   * @description set accessor including call the onchange callback
   */
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      if (this.mode !== 'select') {
        this.onChangeCallback(v);
      }
    }
    if (!v) {
      this.onChangeCallback(null);
    }
  }

  /**
   * @description Set touched on blur
   */
  onBlur() {
    this.onTouchedCallback();
    this.fucused = false;
    this.blur.emit();
  }

  public onFocus() {
    this.fucused = true;
    this.focus.emit();
  }

  public onClick() {
    if (!this.disabled) {
      this.fucused = true;
      this.click.emit();
    }
  }

  /**
   * @description From ControlValueAccessor interface
   */
  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  /**
   * @description From ControlValueAccessor interface
   */
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
  /**
   * @description Function that returns if the input is invalid
   */
  hasDanger() {
    return this.control
      && (this.control as any).name
      && (this.control.dirty || this.control.touched)
      && !this.control.valid;
  }
  filterData() {
    this.showDropdown = true;
    this.filteredData =  this.array.filter((element) => {
      if (element.name.toLowerCase().includes(this.innerValue.toLowerCase())) {
        return element;
      }
    });
  }
  selectElement(brand) {
    this.showDropdown = false;
    this.innerValue = brand;
    this.onChangeCallback(brand);
  }

  check() {
    if (this.strictSearch) {
      const found = this.array?.find((seller) => {
        return seller.name === this.innerValue;
      });
      if (!found) {
        this.innerValue = null;
        this.onChangeCallback(null);
      }
    }
  }
  closeDropdown() {
    this.showDropdown = false;
  }
}
