
import { Injectable } from '@angular/core';
import { HttpInterceptor,
  HttpRequest,
  HttpHandler, HttpEvent,
  HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { EventsService } from './events.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(
    private router: Router,
    private eventService: EventsService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const request = req;
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401 && err.error.error.code === 'AUTHORIZATION_REQUIRED') {
          localStorage.removeItem('created');
          localStorage.removeItem('ttl');
          localStorage.removeItem('isLoggedin');
          localStorage.removeItem('user');
          this.eventService.publish('clearIntervals', true);
          this.router.navigateByUrl('/login');
        }

        return throwError(err);

      }),
    );
  }

}
