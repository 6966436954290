/* tslint:disable */
import { Injectable } from '@angular/core';
import { Email } from '../../models/Email';
import { Role } from '../../models/Role';
import { Certification } from '../../models/Certification';
import { ImageRecognition } from '../../models/ImageRecognition';
import { Inspection } from '../../models/Inspection';
import { Client } from '../../models/Client';
import { Setting } from '../../models/Setting';
import { Inspector } from '../../models/Inspector';
import { HistoricalInspection } from '../../models/HistoricalInspection';
import { Notification } from '../../models/Notification';
import { BranchOffice } from '../../models/BranchOffice';
import { FeatureFlag } from '../../models/FeatureFlag';
import { ClientVersion } from '../../models/ClientVersion';
import { ProcedureType } from '../../models/ProcedureType';
import { InspectionFormModule } from '../../models/InspectionFormModule';
import { AccessoryPicture } from '../../models/AccessoryPicture';
import { VehiclePicture } from '../../models/VehiclePicture';
import { Province } from '../../models/Province';
import { InspectionReport } from '../../models/InspectionReport';

export interface Models { [name: string]: any }

@Injectable()
export class SDKModels {

  private models: Models = {
    Email: Email,
    Role: Role,
    Certification: Certification,
    ImageRecognition: ImageRecognition,
    Inspection: Inspection,
    Client: Client,
    Setting: Setting,
    Inspector: Inspector,
    HistoricalInspection: HistoricalInspection,
    Notification: Notification,
    BranchOffice: BranchOffice,
    FeatureFlag: FeatureFlag,
    ClientVersion: ClientVersion,
    ProcedureType: ProcedureType,
    InspectionFormModule: InspectionFormModule,
    AccessoryPicture: AccessoryPicture,
    VehiclePicture: VehiclePicture,
    Province: Province,
    InspectionReport: InspectionReport,
    
  };

  public get(modelName: string): any {
    return this.models[modelName];
  }

  public getAll(): Models {
    return this.models;
  }

  public getModelNames(): string[] {
    return Object.keys(this.models);
  }
}
