import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ClientApi } from '../../../shared/sdk/services/custom/index';
import sweetalert2 from 'sweetalert2';
import { LoopBackAuth } from '../../../shared/sdk/services/core/auth.service';
import { EventsService } from '../../../shared/services/events.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  loadingPage: boolean;
  isSubmitted: boolean;
  formGroup: FormGroup;
  constructor(
    public clientApi: ClientApi,
    public loopBackAuth: LoopBackAuth,
    public router: Router,
    private eventService: EventsService,
    public formBuilder: FormBuilder,
  ) {

    this.isSubmitted = false;
    this.formGroup = this.formBuilder.group({
      username: [null, Validators.compose([Validators.required])],
      password: [null, Validators.compose([Validators.required])],
      rememberMe: [false],
    });
  }

  credentials = {
    username: '',
    password: '',
  };

  ngOnInit() {
    if (localStorage.getItem('isLoggedin')) {
    }
    this.loadingPage = false;
  }

  submit() {
    const values = this.formGroup.value;
    this.credentials.username = values.username;
    this.credentials.password = values.password;
    const rememberMe = values.rememberMe;
    if (this.formGroup.valid) {
      this.clientApi.login(this.credentials)
        .subscribe((data) => {
          const unauthorized = data?.user?.roles?.
          find((rol) => { return rol.name !== 'inspector'; });
          if (unauthorized) {
            data.ttl = parseInt(data.ttl, 10);
            this.loopBackAuth.setRememberMe(rememberMe);
            this.loopBackAuth.setToken(data);
            localStorage.setItem('isLoggedin', 'true');
            localStorage.setItem('user', JSON.stringify(data.user));
            localStorage.setItem('ttl', JSON.stringify(data.ttl));
            localStorage.setItem('created', JSON.stringify(data.created));
            this.eventService.publish('pullInspections', true);
            this.router.navigate(['']);
          }else {
            sweetalert2.fire({
              title: 'Sin autorización',
              text: 'Usted no tiene autorización para ingresar a la app',
              type: 'error',
              confirmButtonText: 'Aceptar',
              customClass: {
                confirmButton: 'btn btn-primary mx-1',
                input: 'swal-input',
                title: 'swal-title',
              },
            });
          }

        },         (error) => {
          this.loadingPage = false;
          if (error.statusCode === 403) {
            sweetalert2.fire({
              title: 'Error!',
              text: 'El usuario está deshabilitado',
              type: 'error',
              confirmButtonText: 'Aceptar',
              customClass: {
                confirmButton: 'btn btn-primary mx-1',
                input: 'swal-input',
                title: 'swal-title',
              },
            });
          }else {
            sweetalert2.fire({
              title: 'Error!',
              text: error.message,
              type: 'error',
              confirmButtonText: 'Aceptar',
              customClass: {
                confirmButton: 'btn btn-primary mx-1',
                input: 'swal-input',
                title: 'swal-title',
              },
            });
          }
        });
    }
  }

  forgetPassword() {
    sweetalert2.fire({
      title: 'Recuperar contraseña',
      input: 'email',
      inputPlaceholder: 'Ingrese su email',
      showCancelButton: true,
      confirmButtonText: 'Recuperar',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      reverseButtons: true,
      customClass: {
        cancelButton: 'btn btn-ligth mx-1',
        confirmButton: 'btn btn-primary mx-1',
        input: 'swal-input',
        title: 'swal-title',
      },
      buttonsStyling: false,
      preConfirm: (email) => {
        return this.clientApi.resetPassword({ email }).toPromise()
          .then((response) => {
            return response;
          })
          .catch((error) => {
            if (error.statusCode === 404) {
              sweetalert2.showValidationMessage(
                'La dirección de correo electrónico es incorrecta.',
              );
            } else {
              sweetalert2.showValidationMessage(
                'Ha ocurrió un error al recuperar la contraseña.' +
                ' Intente nuevamente en unos minutos.',
              );
            }
          });
      },
      allowOutsideClick: () => !sweetalert2.isLoading(),
    }).then((result) => {
      if (!result.dismiss) {
        sweetalert2.fire({
          title: 'Correo enviado',
          confirmButtonText: 'Aceptar',
          text: 'Por favor, verifique su casilla y siga las instrucciones.',
          customClass: {
            confirmButton: 'btn btn-primary mx-1',
            input: 'swal-input',
            title: 'swal-title',
          },
          buttonsStyling: false,
        });
      }
    });
  }
}
