import { Component, ElementRef, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { BranchOfficeApi, ClientApi, HistoricalInspectionApi, ProvinceApi } from '../../shared/sdk/services/custom';
import { ToastrService } from 'ngx-toastr';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import sweetalert2 from 'sweetalert2';
import { ChartOptions, ChartType } from 'chart.js';
import { Label, SingleDataSet } from 'ng2-charts';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import * as moment from 'moment';
import { AlertService } from '../../shared/services/alert.service';
import { AuthGuard } from '../../shared';

@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.scss'],
})
export class AnalysisComponent implements OnInit {

  colors = {
    pickup: {
      backgroundColor: '#78BE20',
      borderColor: '#78BE20',
      hoverBackgroundColor: '#78BE20',
      hoverBorderColor: '#78BE20',
    },
    suv: {
      backgroundColor: '#007749',
      borderColor: '#007749',
      hoverBackgroundColor: '#007749',
      hoverBorderColor: '#007749',
    },
    hatchback: {
      backgroundColor: '#E56A54',
      borderColor: '#E56A54',
      hoverBackgroundColor: '#E56A54',
      hoverBorderColor: '#E56A54',
    },
    sedan: {
      backgroundColor: '#F0B323',
      borderColor: '#F0B323',
      hoverBackgroundColor: '#F0B323',
      hoverBorderColor: '#F0B323',
    },
  };

  inspections;
  charType = 'yearly';
  minValueSlide = 0;
  maxValueSlide = 100;

  isSearching: boolean;

  slideChangeObserver;
  slidersRefresh: EventEmitter<void> = new EventEmitter<void>();

  provinces = [];
  zone;
  months =
    ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio',
      'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

  @ViewChild('termSearchInput', { static: true }) termSearchInput: ElementRef;
  agentCod;
  sourceApp = 'all';

  // table configuration
  public displayedColumns: string[] =
    ['provinceName',  'number', 'percentage'];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild(MatPaginator, { static: true }) public paginator: MatPaginator;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [10, 15, 24];
  elementsNumber: number;
  public dataSource: MatTableDataSource<any>;
  // Geographic filters
  minValueSlideGeo = 0;
  maxValueSlideGeo = 100;
  slideChangeObserverGeo;
  slidersRefreshGeo: EventEmitter<void> = new EventEmitter<void>();
  sourceAppGeo = 'all';
  isLoadingListGeo;
  initDateGeo = moment().startOf('month').toDate();
  endDateGeo;
  vehicleTypeGeo;
  isSearchingGeo;
  inspectionsGeo;
  @ViewChild('statisticsPage', { static: true }) statisticsPage;

  // Pie chart configuration

  // Pie
  public pieChartOptions: ChartOptions = {
    responsive: true,
  };
  public horizontalBarChartLabels: Label[] = [];
  public horizontalBarChartData: SingleDataSet = [];
  public pieChartType: ChartType = 'horizontalBar';
  public pieChartLegend = false;
  public pieChartPlugins = [];
  horizontalBarColors = [];
  params;
  sellers;
  branchOffices;
  user;
  constructor(private historicalInspectionApi: HistoricalInspectionApi,
              private provinceApi: ProvinceApi,
              private toastr: ToastrService,
              private route: ActivatedRoute,
              private clientApi: ClientApi,
              private branchOfficeApi: BranchOfficeApi,
              private alertService: AlertService,
              private authGuard: AuthGuard,
              private router: Router) {
    this.dataSource = new MatTableDataSource([]);
  }

  async ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.clientApi.searchSalesmen().subscribe((sellers) => {
      this.sellers = sellers;
    });
    this.isLoadingList = true;
    this.isLoadingListGeo = true;
    this.route.queryParams.subscribe((params) => {
      this.params = params;
    });
    await  this.getBranchOffices();
    this.getFiltersByURL();
    this.filterGeographicInfo();
    this.filterCustom();
    fromEvent(this.termSearchInput.nativeElement, 'keyup').pipe(
      // get value
      map((event: any) => {
        return event.target.value;
      })
      // if character length greater then 2
      ,
      filter(res => (res.length > 1 || res.length === 0))
      // Time in milliseconds between key events
      ,
      debounceTime(1000)
      // If previous query is diffent from current
      ,
      distinctUntilChanged(),
      // subscription for response
    ).subscribe((text: string) => {
      this.isSearching = true;
      this.filterCustom();
    });
    this.loadProvinces();
  }
  getFiltersByURL() {
    if (this.params['initDate']) {
      this.initDate = new Date(this.params['initDate']);
    }
    if (this.params['endDate']) {
      this.endDate = new Date(this.params['endDate']);
    }
    if (this.params['agentCod']) {
      this.agentCod = this.params['agentCod'];
    }
    if (this.params['vehicleType']) {
      this.vehicleType = this.params['vehicleType'];
    }
    if (this.params['zone']) {
      this.zone = this.params['zone'];
    }
    if (this.params['sourceApp']) {
      this.sourceApp = this.params['sourceApp'];
    }
    if (this.params['minValueSlide']) {
      this.minValueSlide = Number(this.params['minValueSlide']);
    }
    if (this.params['maxValueSlide']) {
      this.maxValueSlide = Number(this.params['maxValueSlide']);
    }
    if (this.params['initDateGeo']) {
      this.initDateGeo = new Date(this.params['initDateGeo']);
    }
    if (this.params['endDateGeo']) {
      this.endDateGeo = new Date(this.params['endDateGeo']);
    }
    if (this.params['vehicleTypeGeo']) {
      this.vehicleTypeGeo = this.params['vehicleTypeGeo'];
    }
    if (this.params['sourceAppGeo']) {
      this.sourceAppGeo = this.params['sourceAppGeo'];
    }
    if (this.params['minValueSlideGeo']) {
      this.minValueSlideGeo = Number(this.params['minValueSlideGeo']);
    }
    if (this.params['maxValueSlideGeo']) {
      this.maxValueSlideGeo = Number(this.params['maxValueSlideGeo']);
    }
  }

  loadChart() {
    this.isLoadingList = true;
    if (this.charType === 'yearly') {
      this.getYearlyInspections();
    } else if (this.charType === 'monthly') {
      this.getMonthlyInspections();
    } else if (this.charType === 'weekly') {
      this.getWeeklyInspections();
    }
  }

  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    // Can't just just `stacked: true` like the docs say
    scales: {
      yAxes: [{
        stacked: true,
      }],
      xAxes: [{
        stacked: true,
      }],
    },
    animation: {
      duration: 750,
    },
  };
  public barChartLabels;
  public barChartType = 'bar';
  public barChartLegend = true;
  public barChartData;

  initDate: Date = moment().startOf('month').toDate();
  endDate: Date;
  vehicleType = 'all';
  isLoadingList: boolean;
  seller: any;
  branchOffice: any = 'all';

  filterCustom() {
    this.charType = 'none';
    this.isLoadingList = true;
    const filter = {
      where: {
        and: [
        ],
      },
      fields: {
        date: true,
        vehicleType: true,
        agentCode: true,
      },
    };
    if (this.initDate) {
      filter.where.and.push({ date: { gt: new Date(this.initDate) } });
    }
    if (this.endDate) {
      const endDate =  moment(this.endDate).endOf('day').toDate();
      filter.where.and.push({ date: { lt: endDate } });
    }
    if (this.vehicleType !== 'all') {
      filter.where.and.push({ vehicleType: this.vehicleType });
    }
    if (this.sourceApp !== 'all') {
      filter.where.and.push({ sourceApp: this.sourceApp });
    }
    if (this.branchOffice && this.branchOffice !== 'all') {
      filter.where.and.push({ branchOfficeId: { eq: this.branchOffice } });
    }
    if (this.seller) {
      filter.where.and.push({ requiredByUserId: this.seller });
    }
    if (this.zone !== 'all') {
      filter.where.and.push({ province: this.zone });
    }
    if (this.minValueSlide > 0) {
      filter.where.and.push({
        'imageRecognitionData.ocrRecognizedPercentage' :
        {
          gte: this.minValueSlide,
        },
      });
    }
    if (this.maxValueSlide < 100) {
      filter.where.and.push({
        'imageRecognitionData.ocrRecognizedPercentage' :
        {
          lte: this.maxValueSlide,
        },
      });
    }
    if (this.agentCod) {
      const condition = { agentCode: { like: `${this.agentCod}`, options:'i' } };
      filter.where.and.push(condition);
    }
    if (filter.where.and.length === 0) {
      delete filter.where;
    }

    this.historicalInspectionApi.find(filter).subscribe(
      (inspections) => {
        this.isLoadingList = false;
        this.isSearching = false;
        this.inspections = inspections;
        this.automaticProcess();
        this.addFiltersToURL();
      },
      (error) => {
        this.toastr.error(
          'No podemos obtener los datos en este momento.' +
          ' Intenta nuevamente más tarde.',
          'Error', {
            positionClass: 'toast-bottom-right',
          });

      },
    );
  }
  addFiltersToURL() {
    this.router.navigate(
      [], {
        relativeTo: this.route,
        queryParams: {
          initDate: this.initDate,
          endDate: this.endDate,
          agentCod: this.agentCod,
          vehicleType: this.vehicleType,
          zone: this.zone,
          sourceApp: this.sourceApp,
          minValueSlide: this.minValueSlide,
          maxValueSlide: this.maxValueSlide,
          initDateGeo: this.initDateGeo,
          endDateGeo: this.endDateGeo,
          vehicleTypeGeo: this.vehicleTypeGeo,
          sourceAppGeo: this.sourceAppGeo,
          minValueSlideGeo: this.minValueSlideGeo,
          maxValueSlideGeo: this.maxValueSlideGeo,
        },
        queryParamsHandling: 'merge',
      });
  }

  automaticProcess() {
    let today = new Date();
    today = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
    let initDate = this.initDate;
    let endDate = this.endDate;
    if (!initDate) {
      const firstDate = this.getFirstDate();
      initDate = (firstDate) ? firstDate : today;
    }
    if (!endDate) {
      endDate = today;
    }

    const diffTime = Math.abs(initDate.getTime() - endDate.getTime());
    const diffDays = diffTime / (1000 * 60 * 60 * 24);
    const diffWeeks = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 7));

    if (diffDays < 30) {
      this.processInDays(initDate, endDate);
    } else if (diffWeeks < 30) {
      this.proccessInWeeks(initDate, endDate);
    } else {
      this.proccessInMonths(initDate, endDate);
    }
  }

  processInDays(initDate, endDate) {
    this.barChartLabels = [];
    const daysOfMonth = this.daysInMonth(initDate.getMonth() + 1, initDate.getFullYear());
    const data = {
      pickupData : [],
      suvData : [],
      hatchbackData : [],
      sedanData : [],
    };

    const diffTime = Math.abs(initDate.getTime() - endDate.getTime());
    const diffDays = diffTime / (1000 * 60 * 60 * 24);

    let colSpace;
    if (diffDays < 15) {
      colSpace = 1;
    } else {
      colSpace = 4;
    }

    const initialDay = initDate.getDate();

    for (let i = 0; i <= diffDays; i = i + 1) {
      data.pickupData[i] = 0;
      data.suvData[i] = 0;
      data.hatchbackData[i] = 0;
      data.sedanData[i] = 0;
      if (i % colSpace === 0) {
        if ((i + initialDay) <= daysOfMonth) {
          this.barChartLabels[i] = `${initialDay + i }/${initDate.getMonth() + 1}`;
        } else {
          this.barChartLabels[i] = `${i + initialDay - daysOfMonth }/${endDate.getMonth() + 1}`;
        }
      } else {
        this.barChartLabels[i] = '';
      }
    }
    this.inspections.forEach((inspection) => {
      const date = new Date(inspection.date);
      if (inspection.vehicleType) {
        if (initialDay <= date.getDate()) {
          data[`${inspection.vehicleType}Data`][date.getDate() - initialDay] =
            data[`${inspection.vehicleType}Data`][date.getDate() - initialDay] + 1;
        } else {
          data[`${inspection.vehicleType}Data`][daysOfMonth - initialDay + date.getDate()] =
            data[`${inspection.vehicleType}Data`][daysOfMonth - initialDay + date.getDate()] + 1;
        }
      }

    });
    this.configureChart(
      data.pickupData,
      data.suvData,
      data.hatchbackData,
      data.sedanData);
  }

  proccessInWeeks(initDate, endDate) {
    this.barChartLabels = [];
    const weeksOfYear = this.getWeekNumberInYear(initDate.getFullYear());
    const data = {
      pickupData : [],
      suvData : [],
      hatchbackData: [],
      sedanData : [],
    };

    const diffTime = Math.abs(initDate.getTime() - endDate.getTime());
    const diffWeeks = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 7));

    let colSpace;
    if (diffWeeks < 12) {
      colSpace = 1;
    } else {
      colSpace = 4;
    }
    const initialWeek = this.getWeekNumber(initDate);
    let mondayDay;
    let sundayDay;
    for (let i = 0; i < diffWeeks; i = i + 1) {
      data.pickupData[i] = 0;
      data.suvData[i] = 0;
      data.hatchbackData[i] = 0;
      data.sedanData[i] = 0;
      if (i % colSpace === 0) {
        if ((i + initialWeek) <= weeksOfYear) {
          mondayDay = this.getMondayOfWeek(initialWeek + i, initDate.getFullYear());
          sundayDay = this.getSundayOfWeek(initialWeek + i, initDate.getFullYear());
          this.barChartLabels[i] = [
            `Semana ${initialWeek + i }`,
            this.dateTextFormat(mondayDay, sundayDay),
            (mondayDay.getFullYear() !== sundayDay.getFullYear()) ?
              `${mondayDay.getFullYear()}-${sundayDay.getFullYear()}` : mondayDay.getFullYear(),
          ];
        } else {
          mondayDay = this.getMondayOfWeek(i - (weeksOfYear - initialWeek), endDate.getFullYear());
          sundayDay = this.getSundayOfWeek(i - (weeksOfYear - initialWeek), endDate.getFullYear());
          this.barChartLabels[i] = [
            `Semana ${i + initialWeek - weeksOfYear}`,
            this.dateTextFormat(mondayDay, sundayDay),
            (mondayDay.getFullYear() !== sundayDay.getFullYear()) ?
              `${mondayDay.getFullYear()}-${sundayDay.getFullYear()}` : mondayDay.getFullYear(),
          ];
        }
      } else {
        this.barChartLabels[i] = '';
      }
    }

    this.inspections.forEach((inspection) => {
      const date = new Date(inspection.date);
      if (initialWeek <= this.getWeekNumber(date)) {
        data[`${inspection.vehicleType}Data`]
          [this.getWeekNumber(date) - initialWeek] += 1;
      } else {
        data[`${inspection.vehicleType}Data`]
          [weeksOfYear - initialWeek + this.getWeekNumber(date)] += 1;
      }
    });
    this.configureChart(
      data.pickupData,
      data.suvData,
      data.hatchbackData,
      data.sedanData);
  }
  proccessInMonths(initDate, endDate) {
    this.barChartLabels = [];
    const pickupData = [];
    const suvData = [];
    const hatchbackData = [];
    const sedanData = [];
    const diffTime = Math.abs(initDate.getTime() - endDate.getTime());
    const diffMonth = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 30));
    let colSpace;
    if (diffMonth < 15) {
      colSpace = 1;
    } else {
      colSpace = 4;
    }
    const initialMonth = initDate.getMonth();
    const year = initDate.getFullYear();
    const fullLabels = [];
    for (let i = 0; i < diffMonth; i = i + 1) {
      pickupData[i] = 0;
      suvData[i] = 0;
      hatchbackData[i] = 0;
      sedanData[i] = 0;
      fullLabels[i] =
        [(initialMonth + i) % 12, year + (Math.ceil((initialMonth + i + 1) / 12) - 1)];
      if (i % colSpace === 0) {
        this.barChartLabels[i] =
        [this.months[(initialMonth + i) % 12] ,
          year + (Math.ceil((initialMonth + i + 1) / 12) - 1)];
      } else {
        this.barChartLabels[i] = '';
      }
    }
    this.inspections.forEach((inspection) => {
      const date = new Date(inspection.date);
      const i = this.getIndexByMonthYear(fullLabels, date);
      switch (inspection.vehicleType) {
        case 'pickup': {
          pickupData[i] = pickupData[i] + 1;
          break;
        }
        case 'SUV': {
          suvData[i] =
            suvData[i] + 1;
          break;
        }
        case 'hachback': {
          hatchbackData[i] =
            hatchbackData[i] + 1;
          break;
        }
        case 'sedan': {
          sedanData[i] =
            sedanData[i] + 1;
          break;
        }
      }
    });
    this.configureChart(pickupData, suvData, hatchbackData, sedanData);
  }
  getWeekNumber(date) {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  }
  getWeekNumberInYear(year) {
    const lastDayOfYear = new Date(year, 11, 31, 0, 0, 0);
    return this.getWeekNumber(lastDayOfYear) - 1;
  }
  getFirstDate() {
    if (this.inspections.length > 0) {
      let moreOld = this.inspections[0];
      this.inspections.forEach((insp) => {
        if (insp.date < moreOld) {
          moreOld = insp.date;
        }
      });
      let date = new Date(moreOld);
      date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
      return date;
    }
    return null;
  }
  getYearlyInspections() {
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), 0, 1);
    const lastDay = new Date(date.getFullYear(), 12, 0);
    const endDate = moment(lastDay).endOf('day').toDate();
    const filter = {
      where: {
        and: [
          {
            date: {
              gte: firstDay,
            },
          },
          {
            date: {
              lte: endDate,
            },
          },
        ],
      },
      fields: {
        date: true,
        vehicleType: true,
        province: true,
      },
    };
    this.historicalInspectionApi.find(filter).subscribe(
      (inspections) => {
        this.isLoadingList = false;
        this.isSearching = false;
        this.inspections = inspections;
        this.proccessYearlyInspection();
      },
      (error) => {
        this.toastr.error(
          'No podemos obtener los datos en este momento. Intenta nuevamente más tarde.',
          'Error', {
            positionClass: 'toast-bottom-right',
          });
      },
    );
  }
  getMonthlyInspections() {
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const filter = {
      where: {
        and: [
          {
            date: {
              gte: firstDay,
            },
          },
          {
            date: {
              lte: lastDay,
            },
          },
        ],
      },
      fields: {
        date: true,
        vehicleType: true,
      },
    };
    this.historicalInspectionApi.find(filter).subscribe(
      (inspections) => {
        this.isLoadingList = false;
        this.isSearching = false;
        this.inspections = inspections;
        this.proccessMonthlyInspection();
      },
      (error) => {
        this.toastr.error(
          'No podemos obtener los datos en este momento. Intenta nuevamente más tarde.',
          'Error', {
            positionClass: 'toast-bottom-right',
          });
      },
    );
  }
  getWeeklyInspections() {
    const date = new Date();
    const firstDay = this.getMonday(new Date());
    const lastDay = this.addDays(firstDay, 6);
    const endDate = moment(lastDay).endOf('day').toDate();
    const filter = {
      where: {
        and: [
          {
            date: {
              gte: firstDay,
            },
          },
          {
            date: {
              lte: endDate,
            },
          },
        ],
      },
      fields: {
        date: true,
        vehicleType: true,
      },
    };
    this.historicalInspectionApi.find(filter).subscribe(
      (inspections) => {
        this.isLoadingList = false;
        this.isSearching = false;
        this.inspections = inspections;
        this.proccessWeeklyInspection(firstDay, lastDay);
      },
      (error) => {
        this.toastr.error(
          'No podemos obtener los datos en este momento. Intenta nuevamente más tarde.',
          'Error', {
            positionClass: 'toast-bottom-right',
          });
      },
    );
  }

  proccessYearlyInspection() {
    this.barChartLabels =
    ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio',
      'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    const pickupData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const suvData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const hatchbackData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const sedanData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    this.inspections.forEach((inspection) => {
      const date = new Date(inspection.date);
      switch (inspection.vehicleType) {
        case 'sedan': {
          pickupData[date.getMonth()] = pickupData[date.getMonth()] + 1;
          break;
        }
        case 'SUV': {
          suvData[date.getMonth()] = suvData[date.getMonth()] + 1;
          break;
        }
        case 'hatchback': {
          hatchbackData[date.getMonth()] = hatchbackData[date.getMonth()] + 1;
          break;
        }
        case 'pickup': {
          sedanData[date.getMonth()] = sedanData[date.getMonth()] + 1;
          break;
        }
      }
    });
    this.configureChart(pickupData, suvData, hatchbackData, sedanData);
  }
  proccessMonthlyInspection() {
    this.barChartLabels = [];
    const today = new Date();
    const daysOfMonth = this.daysInMonth(today.getMonth(), today.getFullYear());
    const pickupData = [];
    const suvData = [];
    const hatchbackData = [];
    const sedanData = [];
    for (let i = 0; i < daysOfMonth; i = i + 1) {
      pickupData[i] = 0;
      suvData[i] = 0;
      hatchbackData[i] = 0;
      sedanData[i] = 0;
      if (i % 4 === 0) {
        this.barChartLabels[i] = `${i + 1}/${today.getMonth() + 1}`;
      } else {
        this.barChartLabels[i] = '';
      }
    }
    this.inspections.forEach((inspection) => {
      const date = new Date(inspection.date);
      switch (inspection.vehicleType) {
        case 'pickup': {
          pickupData[date.getDate()] = pickupData[date.getDate()] + 1;
          break;
        }
        case 'SUV': {
          suvData[date.getDate()] = suvData[date.getDate()] + 1;
          break;
        }
        case 'hatchback': {
          hatchbackData[date.getDate()] = hatchbackData[date.getDate()] + 1;
          break;
        }
        case 'sedan': {
          sedanData[date.getDate()] = sedanData[date.getDate()] + 1;
          break;
        }
      }
    });
    this.configureChart(
      pickupData, suvData, hatchbackData, sedanData);
  }
  proccessWeeklyInspection(firstDay, lastDay) {
    this.barChartLabels = [];
    const today = new Date();
    const pickupData = [];
    const suvData = [];
    const hatchbackData = [];
    const sedanData = [];
    for (let i = firstDay.getDate(); i < firstDay.getDate() + 7; i = i + 1) {
      const index = i - firstDay.getDate();
      pickupData[index] = 0;
      suvData[index] = 0;
      hatchbackData[index] = 0;
      sedanData[index] = 0;
      this.barChartLabels[index] = `${i}/${today.getMonth() + 1}`;
    }
    this.inspections.forEach((inspection) => {
      const date = new Date(inspection.date);
      const index = date.getDate() - firstDay.getDate();
      switch (inspection.vehicleType) {
        case 'pickup': {
          pickupData[index] = pickupData[index] + 1;
          break;
        }
        case 'SUV': {
          suvData[index] = suvData[index] + 1;
          break;
        }
        case 'hatchbackData': {
          hatchbackData[index] = hatchbackData[index] + 1;
          break;
        }
        case 'sedan': {
          sedanData[index] = sedanData[index] + 1;
          break;
        }
      }
    });
    this.configureChart(
      pickupData, suvData, hatchbackData, sedanData);
  }
  daysInMonth (month, year) {
    return new Date(year, month, 0).getDate();
  }
  getMondayOfWeek(week, year) {
    const firstDayOfYear = new Date(year, 0, 1);
    const days = 7 * (week - 1);
    const calcDate = this.addDays(firstDayOfYear, days);
    return this.getMonday(calcDate);
  }
  getSundayOfWeek(week, year) {
    const firstDayOfYear = new Date(year, 0, 1);
    const days = 7 * (week - 1);
    const calcDate = this.addDays(firstDayOfYear, days);
    return this.getSunday(calcDate);
  }
  getMonday(aDay) {
    const d = new Date(aDay);
    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }
  getSunday(aDay) {
    const d = new Date(aDay);
    const day = d.getDay();
    const diff = d.getDate() + (7 - day);
    return new Date(d.setDate(diff));
  }
  addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  configureChart(pickupData, suvData, hatchbackData, sedanData) {
    this.barChartData = [
      {data: pickupData, label: 'Pickup',  fill: true,
        backgroundColor: this.colors.pickup.backgroundColor,
        hoverBackgroundColor: this.colors.pickup.hoverBackgroundColor,
        borderColor: this.colors.pickup.borderColor,
        hoverBorderColor: this.colors.pickup.hoverBorderColor},
      {data: suvData, label: 'SUV',  fill: true,
        backgroundColor: this.colors.suv.backgroundColor,
        hoverBackgroundColor: this.colors.suv.hoverBackgroundColor,
        borderColor: this.colors.suv.borderColor,
        hoverBorderColor: this.colors.suv.hoverBorderColor},
      {data: hatchbackData, label: 'Hatchback',  fill: true,
        backgroundColor: this.colors.hatchback.backgroundColor,
        hoverBackgroundColor: this.colors.hatchback.hoverBackgroundColor,
        borderColor: this.colors.hatchback.borderColor,
        hoverBorderColor: this.colors.hatchback.hoverBorderColor},
      {data: sedanData, label: 'Sedan',  fill: true,
        backgroundColor: this.colors.sedan.backgroundColor,
        hoverBackgroundColor: this.colors.sedan.hoverBackgroundColor,
        borderColor: this.colors.sedan.borderColor,
        hoverBorderColor: this.colors.sedan.hoverBorderColor},
    ];
  }
  sliderChange(event) {
    if (!this.isSearching) {
      this.isSearching = true;
      Observable.create((observer) => {
        this.slideChangeObserver = observer;
      }).pipe(debounceTime(1000)) // wait 1s after the last event before emitting last event
        .pipe(distinctUntilChanged()) // only emit if value is different from previous value
        .subscribe(() => {
          this.filterCustom();
        });
      this.slideChangeObserver.next();
    }
  }
  dateTextFormat(initDate, endDate) {
    const monthNames = ['Ene', 'Feb', 'Mar',
      'Abr', 'May', 'Jun',
      'Jul', 'Ago', 'Sep',
      'Oct', 'Nov', 'Dic'];
    const initDay = initDate.getDate();
    const initMonthIndex = initDate.getMonth();
    const initYear = initDate.getFullYear();
    const endDay = endDate.getDate();
    const endMonthIndex = endDate.getMonth();
    const endYear = endDate.getFullYear();
    let text = '';
    if (initMonthIndex === endMonthIndex) {
      text = `${initDay} -  ${endDay} de  ${monthNames[initMonthIndex]}`;
    } else  {
      text =
        `${initDay} de ${monthNames[initMonthIndex]} - ${endDay} de ${monthNames[endMonthIndex]}`;
    }
    return text;
  }
  loadProvinces() {
    this.provinces = [];
    this.provinceApi.find({ fields:{ name:true } }).subscribe((provinces) => {
      this.provinces = provinces;
      // To remove
    },                                                        (error1) => {
      sweetalert2.fire({
        title: 'Ups! Algo salio mal',
        text: 'Ocurrió un error al obtener las provincias.',
        type: 'error',
        confirmButtonClass: 'btn btn-primary',
        confirmButtonText: 'Salir',
        buttonsStyling: false,
      });
    });
  }
  clearFilter() {
    this.initDate = undefined;
    this.endDate = undefined;
    this.agentCod = '';
    this.branchOffice = 'all';
    this.vehicleType = 'all';
    this.sourceApp = 'all';
    this.minValueSlide = 0;
    this.maxValueSlide = 100;
    this.filterCustom();
  }
  getIndexByMonthYear(fullLabels, date): number {
    let retIndex = null;
    fullLabels.forEach((label, index) => {
      if (date.getMonth() === label[0] && date.getFullYear() === label[1]) {
        retIndex = index;
      }
    });
    return retIndex;
  }

  processGeographicData() {
    let geographicData = [];
    const all = this.inspectionsGeo.length;
    this.provinces.forEach((province) => {
      const number = this.inspectionsGeo.filter((inspection) => {
        return inspection.province === province.name;
      }).length;
      geographicData.push({
        number,
        name: province.name,
        percentage: (all !== 0) ? (number * 100 / all).toFixed(2) : 0,
      });
    });
    geographicData = geographicData.sort((a, b) => {
      if (a.number < b.number) {
        return 1;
      }
      if (a.number > b.number) {
        return -1;
      }
      return 0;
    });
    this.dataSource.data = geographicData;
    this.configureHorizontalBarChart(geographicData);
  }
  filterGeographicInfo() {
    this.isLoadingListGeo = true;
    const filter = {
      where: {
        and: [
        ],
      },
      fields: {
        date: true,
        vehicleType: true,
        province: true,
      },
    };
    if (this.initDateGeo) {
      filter.where.and.push({ date: { gt: new Date(this.initDateGeo) } });
    }
    if (this.endDateGeo) {
      const endDate = moment(this.endDateGeo).endOf('day').toDate();
      filter.where.and.push({ date: { lt: new Date(endDate) } });
    }
    if (this.vehicleTypeGeo !== 'all') {
      filter.where.and.push({ vehicleType: this.vehicleTypeGeo });
    }
    if (this.sourceAppGeo !== 'all') {
      filter.where.and.push({ sourceApp: this.sourceAppGeo });
    }
    if (this.minValueSlideGeo > 0) {
      filter.where.and.push({
        'imageRecognitionData.ocrRecognizedPercentage' :
        {
          gte: this.minValueSlideGeo,
        },
      });
    }
    if (this.maxValueSlideGeo < 100) {
      filter.where.and.push({
        'imageRecognitionData.ocrRecognizedPercentage' :
        {
          lte: this.maxValueSlideGeo,
        },
      });
    }
    if (filter.where.and.length === 0) {
      delete filter.where;
    }
    this.historicalInspectionApi.find(filter).subscribe(
      (inspections) => {
        this.isLoadingListGeo = false;
        this.isSearchingGeo = false;
        this.inspectionsGeo = inspections;
        this.processGeographicData();
        this.addFiltersToURL();
      },
      (error) => {
        this.toastr.error(
          'No podemos obtener los datos en este momento.' +
          ' Intenta nuevamente más tarde.',
          'Error', {
            positionClass: 'toast-bottom-right',
          });

      },
    );
  }
  sliderChangeGeo(event) {
    if (!this.isSearchingGeo) {
      this.isSearchingGeo = true;
      Observable.create((observer) => {
        this.slideChangeObserverGeo = observer;
      }).pipe(debounceTime(1000)) // wait 1s after the last event before emitting last event
        .pipe(distinctUntilChanged()) // only emit if value is different from previous value
        .subscribe(() => {
          this.filterGeographicInfo();
        });
      this.slideChangeObserverGeo.next();
    }
  }
  clearFilterGeo() {
    this.initDateGeo = undefined;
    this.endDateGeo = undefined;
    this.vehicleTypeGeo = 'all';
    this.sourceAppGeo = 'all';
    this.minValueSlideGeo = 0;
    this.maxValueSlideGeo = 100;
    this.filterGeographicInfo();
  }
  configureHorizontalBarChart(geographicData) {
    this.horizontalBarChartLabels = [];
    this.horizontalBarChartData = [];
    let otherAmount = 0;
    geographicData.forEach((provinceData, index) => {
      if (index < 7) {
        this.horizontalBarChartLabels.push(provinceData.name);
        // @ts-ignore
        this.horizontalBarChartData.push(provinceData.number);
      } else {
        otherAmount = otherAmount + provinceData.number;
      }
    });
    this.horizontalBarChartLabels.push('Otro');
    // @ts-ignore
    this.horizontalBarChartData.push(otherAmount);
    this.horizontalBarColors = [
      {backgroundColor: ['#78BE20', '#007749', '#E56A54',  '#F0B323',
        '#F9E1A7', '#005333', '#B3D6C8', '#A1D263']},
    ];
  }
  showPreprint() {
    this.barChartData.forEach((data) => {
      delete data._meta;
    });
    const canvas = {
      barChartData: this.barChartData,
      barChartLabels: this.barChartLabels,
      barChartOptions: this.barChartOptions,
      barChartLegend: this.barChartLegend,
      barChartType: this.barChartType,
    };
    const canvasGeo = {
      horizontalBarChartData: this.horizontalBarChartData,
      horizontalBarChartLabels: this.horizontalBarChartLabels,
      pieChartType: this.pieChartType,
      pieChartOptions: this.pieChartOptions,
      pieChartPlugins: this.pieChartPlugins,
      pieChartLegend: this.pieChartLegend,
      horizontalBarColors: this.horizontalBarColors,
    };

    localStorage.setItem('reportCanvas', JSON.stringify(canvas));
    localStorage.setItem('reportCanvasGeo', JSON.stringify(canvasGeo));
    localStorage.setItem('inspectionNumber', this.inspections.length);
    const filters = {
      initDate: this.initDate,
      endDate: this.endDate,
      agentCod: this.agentCod,
      vehicleType: this.vehicleType,
      sourceApp: this.sourceApp,
      minValueSlide: this.minValueSlide,
      maxValueSlide: this.maxValueSlide,
      province: this.zone,
    };
    localStorage.setItem('filtersCanvas', JSON.stringify(filters));
    const filtersGeo = {
      initDate: this.initDateGeo,
      endDate: this.endDateGeo,
      agentCod: this.agentCod,
      vehicleType: this.vehicleTypeGeo,
      sourceApp: this.sourceAppGeo,
      minValueSlide: this.minValueSlideGeo,
      maxValueSlide: this.maxValueSlideGeo,
    };
    localStorage.setItem('filtersCanvasGeo', JSON.stringify(filtersGeo));
    localStorage.setItem('dataSourceData', JSON.stringify(this.dataSource.data));
    const url = this.router.createUrlTree(['previewReport']);
    window.open(url.toString(), '_blank');
  }
  async  getBranchOffices() {
    const promise = new Promise<any>((resolve, reject) => {
      const filter = {
        where:{},
      };
      if (!this.authGuard.hasRoles(['superadmin'])) {
        filter.where['users'] =  { inq: [this.user.id] } ;
      }
      this.branchOfficeApi.find(filter).subscribe((data:any) => {
        this.branchOffices = data;
        resolve(this.branchOffices);
      },                                          (error) => {
        this.alertService.generalError();
        reject();
      });
    });
    return promise;
  }

}
