import { Component, Input, NgZone, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-customer-data-form',
  templateUrl: './customer-data-form.component.html',
  styleUrls: ['./customer-data-form.component.scss'],
})
export class CustomerDataFormComponent implements OnInit, OnChanges  {
  @Input() module;
  @Input() readOnly;
  @Input() inspection;
  data:any = {
    name:null,
    lastname:null,
    firstOwner:null,
    duplicateKey: null,
    accidents: null,
    officialDealer: null,
    repaired: null,
    observation: null,
  };
  edit: boolean = true;
  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.inspection && changes.module) {
      this.inspection = changes.inspection.currentValue;
      this.module = changes.module.currentValue;
      this.parseData();
    }
  }
  ngOnInit() {
    this.parseData();
  }
  parseData() {
    if (this.module.requiredSubmodules && this.module.requiredSubmodules.length > 0) {
      for (const property in this.data) {
        const item = this.module.requiredSubmodules
            .find((module) => { return module.name === property; });
        if (item) {
          this.data[property] = item.value;
        }
      }
    }
  }

  save() {
    this.module.requiredSubmodules = [];
    for (const property in this.data) {
      this.module.requiredSubmodules.push({
        name: property,
        value: this.data[property],
      });
    }
    this.module.status = 'done';
  }

  isCompletedForm() {
    let isCompleted = true;
    if (!this.data.name || !this.data.lastname || (this.data.repaired && !this.data.observation)) {
      isCompleted = false;
    }
    return isCompleted;
  }

}
