import { Component, NgZone } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { HistoricalInspectionApi } from './shared/sdk';
import * as moment from 'moment';
import { EventsService } from './shared/services/events.service';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'photofied-triunfo-company-web';
  private interval;
  private background: boolean;
  public currentEnv;
  production: boolean;

  constructor(public router: Router,
              private matIconRegistry: MatIconRegistry,
              private historicalInspectionApi: HistoricalInspectionApi,
              private ngZone: NgZone,
              private dialog: MatDialog,
              private  http: HttpClient,
              private eventService: EventsService,
              private domSanitzer: DomSanitizer) {
    // Custom icons registration
    // Trash icon
    this.matIconRegistry.addSvgIcon(
      'custom_trash',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/svg/trash.svg'),
    );
    // Edit icon
    this.matIconRegistry.addSvgIcon(
      'custom_edit',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/svg/edit.svg'),
    );
    this.pullInspections();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'G-57QCVQ068L', {
          page_path: event.urlAfterRedirects,
        },
          );
      }
    },
    );
  }
  ngOnInit() {
    this.background = document.hidden;
    document.addEventListener('visibilitychange',  () => {
      this.background = document.hidden;

    },                        false);
    this.eventService.subscribe('pullInspections', (data) => {
      if (data) {
        this.pullInspections();
      }
    });
    this.eventService.subscribe('clearIntervals', (data) => {
      if (data) {
        clearInterval(this.interval);
      }
    });
    this.checkEnvironment();
  }

  pullInspections() {
    const isLoggedin  = localStorage.getItem('isLoggedin');
    if (isLoggedin) {
      this.interval =  setInterval(() => {
        this.findInspections();
        if (this.router.url.includes('inspectionsList') && !this.background) {
          this.eventService.publish('updateInspectionList', { setNotifiedInspections:false });
        }
      },                           600000);
    }
  }
  findInspections() {
    const date: Date = moment().startOf('day').toDate();
    const today = moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY');
    const lastUpdate = localStorage.getItem('lastUpdateDate');
    if (!lastUpdate) {
      localStorage.setItem('lastUpdateDate', today);
    }
    if (lastUpdate && (moment(today, 'DD/MM/YYYY') > moment(lastUpdate, 'DD/MM/YYYY'))) {
      localStorage.setItem('lastUpdateDate', today);
      localStorage.removeItem('notifiedInspections');
    }
    this.historicalInspectionApi.find(
      { where:
      {
        date: { gte: new Date(date) },
        estimates: { exists:false } } ,
        fields:{ id:true, estimates:true,
        },
      }).subscribe((inspections) => {
        this.notifications(inspections);
      },           (error) => {
        console.error('No se pudieron obtener las inspecciones para notificar');
      });
  }
  async notifications(inspections) {
    let notifiedInspections = JSON.parse(localStorage.getItem('notifiedInspections'));
    let fireNotification: boolean = false;
    inspections.forEach((inspection) => {
      if (Array.isArray(notifiedInspections)) {
        const found =  notifiedInspections.find((element) => { return element === inspection.id; });
        if (!found) {
          fireNotification = true;
          notifiedInspections.push(inspection.id);
        }
      }else {
        fireNotification = true;
        notifiedInspections = [inspection.id];
      }
    });
    if (notifiedInspections && notifiedInspections.length > 0) {
      localStorage.setItem('notifiedInspections', JSON.stringify(notifiedInspections));
    }
    if (fireNotification) {
      if (! ('Notification' in window)) {
        console.log('Web Notification not supported');
        return;
      }
      await Notification.requestPermission((permission) => {
        const notification = new Notification('Photofied', {
          body:'Hay nuevas inspecciones sin cotizar',
          icon:'https://fidtech-public.s3.sa-east-1.amazonaws.com/logo-photofied.webp',
          dir:'auto' ,
          tag: 'my-unique-id',
        });
        fireNotification = false;
        notification.onclick =  (event) => {
          event.preventDefault();
          window.parent.focus();
          this.ngZone.run(() => {
            if (this.router.url.includes('inspectionsList') && this.background) {
              this.eventService.publish('updateInspectionList', { setNotifiedInspections:false });
            }
            if (this.router.url.includes('inspectionGenerator')) {
              this.dialog.closeAll();
            }
            this.router.navigateByUrl('/inspectionsList');
          });
          notification.close();
        };
      });
    }
  }
  checkEnvironment() {
    this.production = environment.production;
    this.http.get(environment.apiUrl).subscribe(async (data:any) => {
      this.currentEnv = data.currentEnv;
    });
  }

}
