import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MockService } from '../../shared/services/mock.service';
import { AlertService } from '../../shared/services/alert.service';
import sweetalert2 from 'sweetalert2';
import { BranchOfficeApi } from '../../shared/sdk';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-branch-offices',
  templateUrl: './branch-offices.component.html',
  styleUrls: ['./branch-offices.component.scss'],
})
export class BranchOfficesComponent implements OnInit {
  @ViewChild('dialogRef', { static: true }) dialogRef: TemplateRef<any>;
  newBranchOffice:any = {
    name: null,
    address: null,
    city: null,
    phoneNumber: null,
  };
  public displayedColumns: string[] =
    ['name',  'address', 'city', 'phone', 'actions'];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild(MatPaginator, { static: true }) public paginator: MatPaginator;
  pageSize = 10;
  pageIndex = 0;
  public dataSource: MatTableDataSource<any>;
  @ViewChild('branchOfficesForm') branchOfficesFormElement:NgForm;

  constructor(public dialog: MatDialog,
              public alertService: AlertService,
              private toastr: ToastrService,
              private branchOfficeApi:BranchOfficeApi,
              public mockService:MockService) {
    this.dataSource = new MatTableDataSource([]);
  }

  ngOnInit(): void {
    this.getBranchOffices();
  }

  createBranchOffice() {
    this.newBranchOffice = {
      name: null,
      address: null,
      city: null,
      phoneNumber: null,
    };
    this.dialog.open(this.dialogRef, {});
  }
  getBranchOffices() {
    this.branchOfficeApi.find().subscribe((data) => {
      this.dataSource.data = data;
    },                                    (error) => {
      this.alertService.generalError();
    });
  }
  saveBranchOffice() {
    if (this.newBranchOffice.id) {
      this.branchOfficeApi.patchAttributes(this.newBranchOffice.id, this.newBranchOffice)
        .subscribe(() => {
          this.getBranchOffices();
          this.dialog.closeAll();
          this.toastr.success(
        `La sucursal ${this.newBranchOffice.name} se ha editado correctamente`,
        'Sucursal editado',
        { positionClass: 'toast-bottom-right' });
        },         (error) => {
          this.alertService.generalError();
        });
    }else {
      this.branchOfficeApi.create(this.newBranchOffice).subscribe(() => {
        this.getBranchOffices();
        this.dialog.closeAll();
        this.toastr.success(
          `La sucursal ${this.newBranchOffice.name} se ha creado correctamente`,
          'Sucursal creada',
          {
            positionClass: 'toast-bottom-right',
          });

      },                                                          (error) => {
        this.alertService.generalError();
      });
    }

  }

  editBranchOffice(branchOffice) {
    this.newBranchOffice = branchOffice;
    this.dialog.open(this.dialogRef, {});
  }

  deleteBranchOffice(branchOffice) {
    sweetalert2.fire({
      title: 'Desactivar la sucursal',
      text: `¿Está seguro que desea desactivar la sucursal ${branchOffice.name}?`,
      type: 'warning',
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si, desactivar',
      customClass: {
        cancelButton: 'btn btn-ligth mx-1',
        confirmButton: 'btn btn-danger mx-1',
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        this.branchOfficeApi.disableBranchOffice(branchOffice.id).subscribe(() => {
          this.getBranchOffices();
        });
      }else {
        branchOffice.isActive = true;
      }
    });
  }
  activeBranchOffice(branchOffice) {
    sweetalert2.fire({
      title: 'Activar la sucursal',
      text: `¿Está seguro que desea activar la sucursal ${branchOffice.name}?`,
      type: 'warning',
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si, activar',
      customClass: {
        cancelButton: 'btn btn-ligth mx-1',
        confirmButton: 'btn btn-danger mx-1',
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        this.branchOfficeApi.enableBranchOffice(branchOffice.id).subscribe(() => {
          this.getBranchOffices();
        });
      }else {
        branchOffice.isActive = false;
      }
    });
  }
}
