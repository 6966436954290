import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-technical-form',
  templateUrl: './technical-form.component.html',
  styleUrls: ['./technical-form.component.scss'],
})
export class TechnicalFormComponent implements OnInit {
  @Input() module;
  @Input() report;
  @Input() readonly;
  @Input() offlineInspection;
  expand: boolean;
  constructor() {}
  ngOnInit() {}
  verifyCompletedForm() {
    let isCompleted = true;
    if (this.module) {
      this.module.requiredSubmodules.forEach((data:any) => {
        if (!data.value && data.required) {
          isCompleted = false;
        }
        if (!data.required && (!data.notApplicable && !data.value)) {
          isCompleted = false;
        }
      });
      return isCompleted;
    }

  }

  clearSubmodule(submodule) {
    submodule.value = null;
    submodule.observation = null;
    submodule.reception = null;
  }

  save() {
    this.module.status = 'done';
  }
  expandItem(module) {
    module.expand = !module.expand;
  }

  verifyValue(id: string) {
    const checked = document.querySelectorAll(`input#${id}:checked`);
    if (checked.length === 0) {
      return false;
    }
    return  true;
  }
}
