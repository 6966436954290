import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BranchOfficeApi, ClientApi, RoleApi } from '../../../shared/sdk/services/custom/index';
import { ToastrService } from 'ngx-toastr';
import sweetalert2 from 'sweetalert2';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MockService } from '../../../shared/services/mock.service';
import { AlertService } from '../../../shared/services/alert.service';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map , filter } from 'rxjs/operators';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit {

  user;
  newUser: any;
  users;

  // table configuration
  public displayedColumns: string[] =
    ['name',  'email', 'role', 'actions'];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild(MatPaginator, { static: true }) public paginator: MatPaginator;
  pageSize = 10;
  pageIndex = 0;
  public dataSource: MatTableDataSource<any>;

  @ViewChild('dialogRef', { static: true }) dialogRef: TemplateRef<any>;
  @ViewChild('userSearchInput', { static: true }) userSearchInput: ElementRef;

  roles;
  isLoading: boolean;
  branchOffices: any;
  role: any;
  filter = {
    branchOffice:null,
    role:null,
    user:null,
  };
  public constructor (
    private clientApi:  ClientApi,
    private roleApi: RoleApi,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private alertService: AlertService,
    public mockService:MockService,
    private branchOfficeApi:BranchOfficeApi,
  ) {
    this.dataSource = new MatTableDataSource([]);
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.isLoading = true;
    this.roleApi.find().subscribe(
      (roles) => {
        this.roles = roles;
      });
    this.getClients();
    this.getBranchOffices();
    this.configureDebounce(this.userSearchInput?.nativeElement);

  }
  getClients() {
    const filter:any = {
      where: {} as any,
    };
    if (this.filter.user) {
      filter.where['name'] = { like: `${this.filter.user}`, options:'i' };
    }

    if (this.filter.branchOffice === 'all') {
      this.filter.branchOffice = null;
    }
    if (this.filter.role === 'all') {
      this.filter.role = null;
    }else {
      filter.include = {
        relation: 'roles',
        scope:{
          where:{ id: this.filter.role  },
        },
      };
    }
    const customHeaders = (data:any) => {
      data.headers.set('filter', [JSON.stringify(filter)]);
      data.normalizedNames.set('filter', 'filter');
      return data;
    };
    this.clientApi.searchClients(null, this.filter.branchOffice , customHeaders).subscribe(
     (clients:any) => {
       this.users = clients;
       this.dataSource.data = clients;
       this.isLoading = false;
     });
  }
  createUser() {
    this.newUser = {};
    this.dialog.open(this.dialogRef, {
      panelClass:'user-edit',
    });
  }
  getBranchOffices() {
    this.branchOfficeApi.find().subscribe((data) => {
      this.branchOffices = data;
      this.branchOffices.map((data) => {
        if (!data.isActive) {
          data.disabled = true;
        }
      });
    },                                    (error) => {
      this.alertService.generalError();
    });
  }
  saveUser() {
    if (this.newUser.id) { // User editing
      this.isLoading = true;
      this.clientApi.patchClient(this.newUser.id, this.newUser).subscribe(
        (editedUser) => {
          this.clientApi
            .addBranchOffices(null, editedUser.id, { branchOffices:this.newUser.branchOffices })
            .subscribe((data) => {
              this.dataSource.data.forEach((user, index) => {
                if (user.id === this.newUser.id) {
                  this.dataSource.data[index] = editedUser;
                }
              });
              this.isLoading = false;
              this.toastr.success(
            `El usuario ${editedUser.name} se ha editado correctamente`,
            'Usuario editado', {
              positionClass: 'toast-bottom-right',
            });
            });
        },
        (error) => {
          this.isLoading = false;
          this.toastr.error(
            'Ha ocurrido un error al editar el usuario',
            'Error',
            {
              positionClass: 'toast-bottom-right',
            });
        },
      );
    } else { // new user
      this.isLoading = true;
      this.clientApi.createClient(this.newUser).subscribe(
        (createdClient) => {
          this.clientApi
            .addBranchOffices(null, createdClient.id, { branchOffices:this.newUser.branchOffices })
            .subscribe((data) => {
              createdClient.isActive = true;
              this.users.push(createdClient);
              this.dataSource.data = this.users;
              this.isLoading = false;
              this.toastr.success(
            `El usuario ${createdClient.name} se ha creado correctamente`,
            'Usuario creado', {
              positionClass: 'toast-bottom-right',
            });
            });
        },
        (error: any) => {
          this.toastr.error(
            'Ha ocurrido un error al crear el usuario',
            'Error',
            {
              positionClass: 'toast-bottom-right',
            });
        });
    }
  }
  editUser(user) {
    this.newUser = user;
    this.newUser.roleId = user.roles[0].id;
    this.branchOfficeApi
      .find({ where:{ users: { inq: [this.newUser.id] } } })
      .subscribe((branchOffices) => {
        this.newUser.branchOffices = branchOffices
          .map((branchOffice:any) => { return branchOffice.id; });
        this.dialog.open(this.dialogRef, {  panelClass:'user-edit' });
      });
  }
  deleteUser(user) {
    sweetalert2.fire({
      title: 'Desactivar usuario',
      text: `¿Está seguro que desea desactivar al usuario ${user.name}?`,
      type: 'warning',
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si, Desactivar',
      customClass: {
        cancelButton: 'btn btn-ligth mx-1',
        confirmButton: 'btn btn-danger mx-1',
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        this.isLoading = true;
        this.clientApi.disableClient(user.id).subscribe(
          (deletedUser) => {
            this.dataSource.data = this.users;
            this.isLoading = false;
            this.toastr.success(
              `El usuario ${user.name} ha sido desactivado`,
              'Usuario desactivado',
              {
                positionClass: 'toast-bottom-right',
              });
          },
          (error) => {
            this.isLoading = false;
            this.toastr.error(
              'Ha ocurrido un error al desactivar el usuario',
              'Error',
              {
                positionClass: 'toast-bottom-right',
              });
          },
        );
      } else if (result.dismiss === sweetalert2.DismissReason.cancel) {
        user.isActive = true;

      }
    });
  }
  canEditUser(userToEdit) {
    return userToEdit.roles[0].hierarchy > this.user.roles[0].hierarchy;
  }

  clearFilter() {
    this.filter = {
      branchOffice: null,
      role:null,
      user:null,
    };
    this.getClients();
  }

  checkRole() {
    const selectedRole = this.roles.find((role) => {
      return role.id === this.newUser.roleId;
    });
    return selectedRole?.name === 'superadmin';
  }
  configureDebounce(nativeElement) {
    if (nativeElement) {
      fromEvent(nativeElement, 'keyup').pipe(
        map((event: any) => {
          return event.target.value;
        }),
        filter(res => (res.length > 1 || res.length === 0)),
        debounceTime(1000),
        distinctUntilChanged(),
      ).subscribe((text: string) => {
        this.getClients();
      });
    }

  }

  activeUser(user) {
    sweetalert2.fire({
      title: 'Activar usuario',
      text: `¿Está seguro que desea activar al usuario ${user.name}?`,
      type: 'warning',
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si, Activar',
      customClass: {
        cancelButton: 'btn btn-ligth mx-1',
        confirmButton: 'btn btn-danger mx-1',
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        this.isLoading = true;
        this.clientApi.enableClient(user.id).subscribe(
          (deletedUser) => {
            this.dataSource.data = this.users;
            this.isLoading = false;
            this.toastr.success(
              `El usuario ${user.name} ha sido activado`,
              'Usuario activado',
              {
                positionClass: 'toast-bottom-right',
              });
          },
          (error) => {
            this.isLoading = false;
            this.toastr.error(
              'Ha ocurrido un error al activar el usuario',
              'Error',
              {
                positionClass: 'toast-bottom-right',
              });
          },
        );
      } else if (result.dismiss === sweetalert2.DismissReason.cancel) {
        user.isActive = false;

      }
    });
  }

  areRequiredBranchOffices():boolean {
    if (this.checkRole()) {
      return false;
    }
    return !this.newUser.branchOffices || this.newUser?.branchOffices?.length === 0;
  }
}
