/**
 * Created by aderenzis on 02/08/17.
 */
import { FormControl } from '@angular/forms';

export class GobalValidators {

  static validarCuit(control: FormControl): any {
    let cuit = control.value;
    if (!cuit) {
      return {
        invalidCuit: true,
      };
    }
    cuit = cuit.replace(/[^0-9]/g, '');
    if (cuit.length !== 11) {
      return {
        invalidCuit: true,
      };
    }
    let acumulado = 0;
    const digitos = cuit.split('');
    const digito = digitos.pop();

    for (let i = 0; i < digitos.length; i = i + 1) {
      acumulado += +digitos[9 - i] * (2 + (i % 6));
    }

    let verif = 11 - (acumulado % 11);
    if (verif === 11) {
      verif = 0;
    } else if (verif === 10) {
      verif = 9;
    }

    if (digito === verif.toString()) {
      return null;
    }
    return {
      invalidCuit: true,
    };

  }

  static mailFormat(control: FormControl): any {

    const EMAIL_REGEXP =
      // tslint:disable-next-line:max-line-length
      /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

    if (control.value !== '' && (control.value.length <= 5 || !EMAIL_REGEXP.test(control.value))) {
      return { incorrectMailFormat: true };
    }

    return null;
  }

  static phonenumber(control: FormControl): any {
    const phoneno = /^\+?\d{1,3}?[- .]?\(?(?:\d{2,3})\)?[- .]?\d\d\d[- .]?\d\d\d\d$/;
    if (control.value.match(phoneno)) {
      return null;
    }
    return { incorrectPhoneFormat: true };
  }
  static url (control: FormControl): any {
    const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
    if (control.value.match(pattern)) {
      return null;
    }
    return { incorrectUrlFormat: true };
  }
}
