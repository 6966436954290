import { Injectable } from '@angular/core';
import sweetalert2 from 'sweetalert2';

@Injectable(
    { providedIn: 'root' },
)
export class AlertService {
  constructor() {
  }
  generalError() : any {
    return this.sweetAlert('error',
                           'Ups! Algo salio mal',
                           'Por favor volvé a intentarlo en unos minutos.',
                           'Aceptar');
  }
  sweetAlert(type, title, text, button?) {
    return  sweetalert2.fire({
      title,
      type,
      text: `${text}`,
      confirmButtonColor: 'var(--primary)',
      confirmButtonText: button,
    });
  }
}
