import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MockService {

  post() {
    return of({});
  }
  patch() {
    return of({});
  }
  delete() {
  }
  getBranchOffices() {
    return of(JSON.parse(localStorage.getItem('branchOffices')));
  }
}
