import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private sidebar = new Subject<any>();

  setStatusSidebar(data) {
    this.sidebar.next(data);
  }
  getStatusSidebar(): Subject<any> {
    return this.sidebar;
  }

}
