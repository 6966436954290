import {
  AfterViewInit, Component, ElementRef,
  EventEmitter, OnDestroy, OnInit, ViewChild,
} from '@angular/core';
import sweetalert2 from 'sweetalert2';
import { BranchOfficeApi, HistoricalInspectionApi, ProvinceApi } from '../../../shared/sdk/services/custom/index';
import { ActivatedRoute, Router } from '@angular/router';
import { HistoricalInspection } from '../../../shared/sdk/models';
import { fromEvent, Observable } from 'rxjs';
import * as _ from 'lodash';
import { WorkBook, WorkSheet, writeFileXLSX as writeFile, utils } from 'xlsx';

import {
  debounceTime,
  map,
  distinctUntilChanged,
  filter,
} from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { EventsService } from '../../../shared/services/events.service';
import { MockService } from '../../../shared/services/mock.service';
import { AlertService } from '../../../shared/services/alert.service';
import { AuthGuard } from '../../../shared';

@Component({
  selector: 'app-inspections-list',
  templateUrl: './inspections-list.component.html',
  styleUrls: ['./inspections-list.component.scss'],
})
export class InspectionsListComponent implements OnInit, AfterViewInit, OnDestroy {

  // table configuration
  public displayedColumns: string[] =
    ['licencePlate',  'vehicleType',
      'requiredBy',
      'inspector', 'date', 'origin', 'estimates'];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild(MatPaginator, { static: true }) public paginator: MatPaginator;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [10, 25, 50];
  elementsNumber: number;
  inspections: HistoricalInspection[];

  slideChangeObserver;
  downloadLoading;

  @ViewChild('removeTokenConfirmationTemplate', { static: true })
  public removeTokenConfirmationTemplate: ElementRef;
  @ViewChild('agentSearchInput', { static: true }) agentCodeInput: ElementRef;
  @ViewChild('transactionIDInput', { static: true }) transactionIDInput: ElementRef;
  @ViewChild('licencePlateSearchInput', { static: true }) licencePlateSearchInput: ElementRef;

  // Filters
  initDate: Date;
  endDate: Date;
  agentCode: string;
  transactionId: string;
  licencePlate: string;

  vehicleType = 'all';
  minValueSlide = 0;
  maxValueSlide = 100;
  slidersRefresh: EventEmitter<void> = new EventEmitter<void>();
  public dataSource: MatTableDataSource<any>;
  firstLoad = false;
  // others
  isLoadingList: boolean;
  isSearching: boolean;
  inspectionsSeen;
  zone;
  provinces = [];
  procedureType = 'all';
  params;
  branchOffices;
  isAdmin;
  origin = 'all';
  branchOffice: any;
  userId;
  constructor(
    private historicalInspectionApi: HistoricalInspectionApi,
    private provinceApi: ProvinceApi,
    private route: ActivatedRoute,
    private router: Router,
    private eventService: EventsService,
    private alertService: AlertService,
    private branchOfficeApi: BranchOfficeApi,
    private mockService: MockService,
    private authGuard: AuthGuard,
    private toastr: ToastrService) {
    this.dataSource = new MatTableDataSource([]);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user'));
    this.userId = user.id;
    this.isAdmin =
      !!user.roles.filter(role => role.name === 'superadmin').length;
    this.isLoadingList = true;
    this.firstLoad = true;
    this.route.queryParams.subscribe((params) => {
      this.params = params;
    });
    this.eventService.subscribe('updateInspectionList', (data) => {
      if (data) {
        this.loadHistoricalInspections(data.setNotifiedInspections);
      }
    });
    this.inspectionsSeen =
        localStorage.getItem('inspectionsSeen') ?
          JSON.parse(localStorage.getItem('inspectionsSeen')) : [];
    await this.getBranchOffices();
    this.getFiltersByURL();
    this.loadHistoricalInspections();
    this.loadListenerTerm();
    this.loadProvinces();
  }

  ngOnDestroy() {
    this.eventService.destroy('updateInspectionList');
  }

  /**
   * Listen the input for search by term.
   */
  loadListenerTerm() {
    this.configureDebounce(this.transactionIDInput?.nativeElement);
    this.configureDebounce(this.agentCodeInput?.nativeElement);
    this.configureDebounce(this.licencePlateSearchInput?.nativeElement);
  }
  async  getBranchOffices() {
    const promise = new Promise<any>((resolve, reject) => {
      const filter = {
        where:{},
      };
      if (!this.authGuard.hasRoles(['superadmin'])) {
        filter.where['users'] =  { inq: [this.userId] } ;
      }
      this.branchOfficeApi.find(filter).subscribe((data:any) => {
        this.branchOffices = data;
        if (data.length === 1) {
          this.branchOffice = this.branchOffices[0].id;
        }else {
          this.displayedColumns.splice(5, 0, 'branchOffice');
        }
        resolve(this.branchOffices);
      },                                          (error) => {
        if (error && error.statusCode !== 401) {
          this.alertService.generalError();
        }
        reject();
      });
    });
    return promise;
  }
  configureDebounce(nativeElement) {
    if (nativeElement) {
      fromEvent(nativeElement, 'keyup').pipe(
        map((event: any) => {
          return event.target.value;
        }),
        filter(res => (res.length > 1 || res.length === 0)),
        debounceTime(1000),
        distinctUntilChanged(),
      ).subscribe((text: string) => {
        this.isSearching = true;
        this.loadHistoricalInspections();
      });
    }

  }

  /**
   * get the query string params
   */
  getFiltersByURL() {
    if (this.params['initDate']) {
      this.initDate = new Date(this.params['initDate']);
    }
    if (this.params['endDate']) {
      this.endDate = new Date(this.params['endDate']);
    }
    if (this.params['searchText']) {
      this.agentCode = this.params['searchText'];
    }
    if (this.params['vehicleType']) {
      this.vehicleType = this.params['vehicleType'];
    }
    if (this.params['zone']) {
      this.zone = this.params['zone'];
    }
    if (this.params['procedureType']) {
      this.procedureType = this.params['procedureType'];
    }
    if (this.params['minValueSlide']) {
      this.minValueSlide = Number(this.params['minValueSlide']);
    }
    if (this.params['maxValueSlide']) {
      this.maxValueSlide = Number(this.params['maxValueSlide']);
    }
    if (this.params['pageSize']) {
      this.pageSize = Number(this.params['pageSize']);
    }
    if (this.params['pageIndex']) {
      this.pageIndex = Number(this.params['pageIndex']);
    }
  }
  /**
   * get the provinces from server
   */
  loadProvinces() {
    this.provinceApi.find(({ fields:{ name:true } })).subscribe(
      (provinces) => {
        this.provinces = provinces;
      },
      (error1) => {
        console.error(error1);
        sweetalert2.fire({
          title: 'Ups! Algo salio mal',
          text: 'Ocurrió un error al obtener las provincias.',
          type: 'error',
          confirmButtonClass: 'btn btn-primary',
          confirmButtonText: 'Salir',
          buttonsStyling: false,
        });
      });
  }

  /**
   * get the inspections from server, using the filters and paginations
   */
  loadHistoricalInspections(setNotifiedInspections:boolean = true, noResetPagination?: boolean) {
    this.isLoadingList = true;
    const filter = {
      where: this.configureWhereFilter(),
      fields: {
        id: true,
        transactionId: true,
        licencePlate: true,
        vehicleType: true,
        confidence: true,
        agentCode: true,
        inspectionForm: true,
        sourceApp: true,
        status: true,
        date: true,
        requiredBy: true,
        branchOfficeName: true,
        estimates: true,
        imageRecognitionData: true,
        province: true,
        procedureType: true,
        isSuccessful: true,
        failedMsgList: true,
      },
      limit: this.pageSize,
      skip: 0 * this.pageSize,
      order: 'date DESC',
    };

    if (noResetPagination) {
      filter.limit = this.pageSize;
      filter.skip = this.pageIndex * this.pageSize;
    } else {
      this.pageIndex = 0;
    }

    if (!filter.where) {
      delete filter.where;
    }
    this.historicalInspectionApi.count(filter.where).subscribe((size) => {
      this.historicalInspectionApi.find(filter).subscribe(
        (dataList) => {
          this.translateTexts(dataList).then((translatedList) => {
            this.inspections = translatedList;
            if (setNotifiedInspections) {
              let notifiedInspections = JSON.parse(localStorage.getItem('notifiedInspections'));
              this.inspections.forEach((inspection) => {
                if (!inspection.estimates) {
                  if (Array.isArray(notifiedInspections)) {
                    const found =  notifiedInspections.
                    find((element) => { return element === inspection.id; });
                    if (!found) {
                      notifiedInspections.push(inspection.id);
                    }
                  }else {
                    notifiedInspections = [inspection.id];
                  }
                }
              });
              if (notifiedInspections && notifiedInspections.length > 0) {
                localStorage.setItem('notifiedInspections', JSON.stringify(notifiedInspections));
              }
            }
            this.inspections.map((inspection: any) => {
              const found = this.inspectionsSeen.find(e => inspection.id === e);
              if (found) {
                inspection.visited = true;
              }
            });
            this.containRelatedIspections(this.inspections);
            this.dataSource.data = this.inspections;
            setTimeout(() => {
              this.elementsNumber = size.count;
            });
            this.isLoadingList = false;
            this.isSearching = false;
            this.addFiltersToURL();
          }).catch((e) => {
            console.error(e);
            sweetalert2.fire({
              title: 'Ups! Algo salio mal',
              text: 'Ocurrió un error al procesar la información.',
              type: 'error',
              confirmButtonClass: 'btn btn-primary',
              confirmButtonText: 'Salir',
              buttonsStyling: false,
            });
          });
        },
        (error1) => {
          sweetalert2.fire({
            title: 'Ups! Algo salio mal',
            text: 'Ocurrió un error al obtener las inspecciones.',
            type: 'error',
            confirmButtonClass: 'btn btn-primary',
            confirmButtonText: 'Salir',
            buttonsStyling: false,
          });
        });
    });
  }
  /**
   * update the filters in query string
   */
  addFiltersToURL() {
    this.router.navigate(
      [], {
        relativeTo: this.route,
        queryParams: {
          initDate: this.initDate,
          endDate: this.endDate,
          searchText: this.agentCode,
          vehicleType: this.vehicleType,
          zone: this.zone,
          procedureType: this.procedureType,
          minValueSlide: this.minValueSlide,
          maxValueSlide: this.maxValueSlide,
          pageSize: this.pageSize,
          pageIndex: this.pageIndex,
        },
        queryParamsHandling: 'merge',
      });
  }

  /**
   * Assign the value 'hasRelatedInspection' to each inspection in 'inspections' params
   * hasRelatedInspection is setted in true when contain 2 or more inspections,
   * else hasRelatedInspection will be false
   * This functionality is only for the inspections that can be grouped by licence Plate
   * @param inspections
   */
  containRelatedIspections(inspections) {
    const groupIds = [];
    const regexLicencePlateCar =
      '^[a-zA-Z]{3}[ ]*[0-9]{3}$|^[a-zA-Z]{2}[ ]*[0-9]{3}[ ]*[a-zA-Z]{2}$';
    inspections.forEach((inspection) => {
      if (inspection.licencePlate && inspection.licencePlate.match(regexLicencePlateCar)) {
        groupIds.push(inspection.licencePlate);
      }
    });
    const filter = {
      where: {
        licencePlate: { inq: groupIds },
      },
      fields: {
        licencePlate: true,
      },
    };
    this.historicalInspectionApi.find(filter).subscribe(
      (dataList) => {
        const groupedData = _.groupBy(dataList, (data) => {
          return data.licencePlate;
        });
        inspections.forEach((inspection) => {
          if (groupedData[inspection.licencePlate]
            && groupedData[inspection.licencePlate].length > 1) {
            inspection.hasRelatedInspection = true;
          }
        });
      });
  }

  /**
   * Redirect to the details page usign the inspectionId params,
   * and set the inspection as visited in localstorage
   * @param inspectionId
   * @param visited
   */
  goToDetails(inspectionId, visited?) {
    if (!visited) {
      this.inspectionsSeen.push(inspectionId);
      localStorage.setItem('inspectionsSeen', JSON.stringify(this.inspectionsSeen));
    }
  }

  /**
   * Apply the traduction in the vehicleTypeTraduction property for each inspection
   * in the inspection list filteredInspections give by params
   * @param filteredInspections
   */
  translateTexts(filteredInspections): Promise<any> {
    const promise = new Promise((resolve, reject) => {
      filteredInspections.forEach((inspection, index) => {
        inspection.position = index;
        inspection.vehicleTypeTraduction =
          (inspection.vehicleType === 'pickup') ? 'Pickup' :
            (inspection.vehicleType === 'suv') ? 'SUV' :
              (inspection.vehicleType === 'hatchback') ? 'Hatchback' :
                (inspection.vehicleType === 'sedan') ? 'Sedan' : 'Sin Definir';

      });
      resolve(filteredInspections);
    });
    return promise;
  }

  /**
   * Listen the changes applyed in the confidence slider and update the values
   */
  sliderChange() {
    const time = 1000;
    if (!this.isSearching) {
      this.isSearching = true;
      Observable.create((observer) => {
        this.slideChangeObserver = observer;
      }).pipe(debounceTime(time)) // wait 1s after the last event before emitting last event
        .pipe(distinctUntilChanged()) // only emit if value is different from previous value
        .subscribe(() => {
          this.loadHistoricalInspections();
        });
      this.slideChangeObserver.next();
    }
    this.firstLoad = false;
  }
  /**
   * Allow move between pages in the table
   * @param event
   */
  onPaginateChange(event) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.loadHistoricalInspections(true, true);
  }
  /**
   * Clean the filters. Is called for the button 'Limpiar filtros'
   */
  clearFilter() {
    this.initDate = undefined;
    this.endDate = undefined;
    this.agentCode = '';
    this.licencePlate = null;
    this.vehicleType = 'all';
    this.procedureType = 'all';
    this.origin = 'all';
    this.minValueSlide = 0;
    this.maxValueSlide = 100;
    this.pageIndex = 0;
    this.pageSize = 10;
    this.loadHistoricalInspections();
  }

  /**
   * Force refresh. Is called for the button 'Actualizar'
   */
  refresh() {
    this.loadHistoricalInspections();
  }

  export(): void {
    this.downloadLoading = true;
    const filter = {
      where: this.configureWhereFilter(),
      fields: {
        licencePlate: true,
        vehicleType: true,
        agentCode: true,
        date: true,
        province: true,
        procedureType: true,
        transactionId: true,
      },
      order: 'date DESC',
    };
    if (!filter.where) {
      delete filter.where;
    }
    this.historicalInspectionApi.count(filter.where).subscribe((size) => {
      if (size.count > 25000) {
        this.downloadLoading = false;
        this.toastr.error(
          `El tamaño del archivo que desea generar es de ${size.count} filas. El máximo de filas permitidas es de 25000.`,
          'Error',
          {
            positionClass: 'toast-bottom-right',
          });
      } else {
        this.historicalInspectionApi.find(filter).subscribe(
         (inspections) => {
           inspections.map((inspection: any) => {
             inspection.vehicleType = (inspection.vehicleType === 'car') ? 'Automóvil' :
               (inspection.vehicleType === 'motorbike') ? 'Moto' :
                 (inspection.vehicleType === 'bike') ? 'Bicicleta' :
                   (inspection.vehicleType === '0km') ? '0km' :
                     (inspection.vehicleType === 'truck') ? 'Camión' :
                       (inspection.vehicleType === 'bus') ? 'Colectivo' :
                         (inspection.vehicleType === 'trailer') ? 'Trailer' :
                           (inspection.vehicleType === 'semitrailer') ? 'Semitrailer' :
                             (inspection.vehicleType === 'caravan') ? 'Casilla Rodante' :
                               (inspection.vehicleType === 'tractor') ? 'Tractor' : 'Sin Definir';
             inspection.procedureType = (inspection.procedureType === 'incident') ? 'Siniestro' : 'Inspección previa';
             inspection.date = moment(inspection.date).format('DD/MM/YYYY HH:mm:ss');
           });
           const ws: WorkSheet = utils.json_to_sheet(inspections);
           let cellAddress = { c: 0, r: 0 };
           let cellRef = utils.encode_cell(cellAddress);
           ws[cellRef].v = 'Tipo de trámite';
           cellAddress = { c: 1, r: 0 };
           cellRef = utils.encode_cell(cellAddress);
           ws[cellRef].v = 'Código de productor';
           cellAddress = { c: 2, r: 0 };
           cellRef = utils.encode_cell(cellAddress);
           ws[cellRef].v = 'Patente';
           cellAddress = { c: 3, r: 0 };
           cellRef = utils.encode_cell(cellAddress);
           ws[cellRef].v = 'Tipo de vehículo';
           cellAddress = { c: 4, r: 0 };
           cellRef = utils.encode_cell(cellAddress);
           ws[cellRef].v = 'Id interno';
           cellAddress = { c: 5, r: 0 };
           cellRef = utils.encode_cell(cellAddress);
           ws[cellRef].v = 'Provincia';
           cellAddress = { c: 6, r: 0 };
           cellRef = utils.encode_cell(cellAddress);
           ws[cellRef].v = 'Fecha';

           /* generate workbook and add the worksheet */
           const wb: WorkBook = utils.book_new();
           utils.book_append_sheet(wb, ws, 'Sheet1');

           /* save to file */
           writeFile(wb, 'testXlsx.xlsx');
           this.downloadLoading = false;
         },
         (error1) => {
           sweetalert2.fire({
             title: 'Ups! Algo salio mal',
             text: 'Ocurrió un error al obtener las inspecciones.',
             type: 'error',
             confirmButtonClass: 'btn btn-primary',
             confirmButtonText: 'Salir',
             buttonsStyling: false,
           });
         });
      }
    });
  }
  configureWhereFilter(): Object {
    const where = {
      and: [],
    };
    if (this.initDate) {
      where.and.push({ date: { gt: new Date(this.initDate) } });
    } else {
      localStorage.removeItem('initDate');
    }
    if (this.endDate) {
      where.and.push({ date: { lt: moment(this.endDate).endOf('day').toDate() } });
    }
    if (this.branchOffice === 'all' || !this.branchOffice) {
      // where.and.push({ branchOfficeId: {
      //   inq: this.branchOffices.map((branchOffice) => { return branchOffice.id; }),
      // },
      // });
    } else {
      where.and.push({ branchOfficeId: { eq: this.branchOffice } });
    }
    if (this.vehicleType !== 'all') {
      where.and.push({ vehicleType: this.vehicleType });
    }
    if (this.procedureType !== 'all') {
      if (this.procedureType === 'home') {
        where.and.push({ procedureType: 'incident' });
        where.and.push({ vehicleType: this.procedureType });
      }else if (this.procedureType === 'commerce') {
        where.and.push({ procedureType: 'incident' });
        where.and.push({ vehicleType: this.procedureType });
      } else {
        where.and.push({ procedureType: this.procedureType });
        where.and.push({ vehicleType: { neq: 'home' } });
        where.and.push({ vehicleType: { neq: 'commerce' } });
      }
    }
    if (this.origin !== 'all') {
      where.and.push({ sourceApp:  this.origin });
    }
    if (this.zone !== 'all') {
      where.and.push({ province: this.zone });
    }
    if (this.minValueSlide > 0) {
      where.and.push({
        'imageRecognitionData.ocrRecognizedPercentage':
        {
          gte: this.minValueSlide,
        },
      });
    }
    if (this.maxValueSlide < 100) {
      where.and.push({
        'imageRecognitionData.ocrRecognizedPercentage':
        {
          lte: this.maxValueSlide,
        },
      });
    }
    if (this.licencePlate) {
      where.and.push({ licencePlate: { like: `${this.licencePlate.toUpperCase()}` } });
    }
    if (this.agentCode) {
      where.and.push({ agentCode: { like: `${this.agentCode}`, options:'i' } });
    }
    if (this.transactionId) {
      where.and.push({ transactionId: { like: `${this.transactionId}` } });
    }
    if (where.and.length === 0) {
      return null;
    }
    return where;
  }
}
