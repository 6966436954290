import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HistoricalInspectionApi, InspectionReportApi } from '../../../shared/sdk/services/custom';
import { HttpClient } from '@angular/common/http';
import * as lottie from '../../../../assets/lottie/lottie.js';
import { ToastrService } from 'ngx-toastr';
import { GoogleMap } from '@angular/google-maps';

declare var require: any;
const exifReader = require('exifreader');

const DEFAULT_ZOOM = 15;

@Component({
  selector: 'app-one-page',
  templateUrl: './inspection-detail-one-page.component.html',
  styleUrls: ['./inspection-detail-one-page.component.scss'],
})
export class InspectionDetailOnePageComponent implements OnInit {

  inspection: any;
  vehicleType;

  bounds: any = false;

  pictures = [];
  inspectionId: string;
  pictureName;
  picture;
  ocrData;
  enabledLens = false;
  lensHeight = null;
  lensWidth = null;
  circularLens = null;
  showTabOne = true;
  exifImage;
  anim;
  expired = false;
  inspectionNotExist = false;
  zoom = DEFAULT_ZOOM - 1;
  @ViewChild('googleMapRef')
  googleMaps : GoogleMap;
  technicalModules;
  @ViewChildren(GoogleMap)
  googleMapsElements : GoogleMap[];
  title;
  signature;
  vehicleModule: any;
  customerModule: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private historicalInspectionApi: HistoricalInspectionApi,
    private inspectionReportApi: InspectionReportApi,
    private router: Router,
    private route: ActivatedRoute,
    private  http: HttpClient,
    private toastr: ToastrService,
  ) {
    this.inspectionId = this.route.snapshot.paramMap.get('id');
  }
  ngOnInit() {
    this.getInspection();
    const animData = {
      container: document.getElementById('lottie'),
      renderer: 'svg',
      loop: false,
      autoplay: true,
      path: 'assets/animations/ended.json',
    };
    this.anim = lottie.loadAnimation(animData);

  }
  getInspection() {
    const filter = {
      where: {
        id: this.inspectionId,
      },
      include: ['picturesPacks'],
    };
    this.inspectionReportApi.findById(this.inspectionId).subscribe(
      (inspection) => {
        this.inspection = inspection;
        this.signature =  this.getModule('signature');
        this.title  = this.getHeaderTitle();
        this.vehicleModule = this.getModule('vehicle');
        this.customerModule = this.getModule('customer');
        this.technicalModules = this.getTechnicalModules();
        this.expired = this.hasExpired();
        this.getImagesInformation();
        localStorage.setItem('inspection', JSON.stringify(this.inspection));
      },
      (err: any) => {
        this.inspectionNotExist = true;
      },
    );
  }
  getImagesInformation() {
    this.inspection.pictures.forEach((picture, i) => {
      this.http.get(
        `${picture.link}?origin=example.com`, {
          responseType: 'blob' }).subscribe(async (imageBlob) => {
            const imageFile = new File(
          [imageBlob],
          'thumbnail', {
            type: 'jpeg', lastModified: Date.now(),
          });
            const tags = await exifReader.load(imageFile);
            picture.exifImage = tags;
        // Getting certification data
            if (this.inspection.certificationData &&
          this.inspection.certificationData.length > 0) {
              const certificationDataFiltered =
            this.inspection.certificationData.filter((value, index) => {
              return value.pictureName === picture.name;
            });
              picture.certificationData = certificationDataFiltered[0];
            }
          });
    });
  }
  changeTab(text) {
    if (text === 'tab 1') {
      this.showTabOne = true;
    } else {
      this.showTabOne = false;
    }
  }
  enableLends() {
    this.enabledLens = true;
    this.lensHeight = 200;
    this.lensWidth = 200;
    this.circularLens = true;
  }
  hasExpired() {
    const unixTokenCreation = new Date(this.inspection.creationDateReport).getTime() / 1000;
    const unixNow = Date.now() / 1000;
    const unixTokenExpiration = unixTokenCreation + this.inspection.ttl;
    let res;
    if (unixNow > unixTokenExpiration) {
      res = true;
    } else {
      res = false;
    }
    return res;
  }
  copyToClipboard(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.success(
      'Texto copiado al portapapeles',
      'Copiado',
      {
        positionClass: 'toast-bottom-right',
      });
  }

  getModule(name) {
    return this.inspection.inspectionForm.data.find((data) => {
      return data.name === name;
    });
  }

  getTechnicalModules() {
    const element = this.inspection.inspectionForm.data.filter((data) => {
      return data.type === 'technical';
    });
    element.forEach((module) => {
      module.requiredSubmodules.forEach((submodule) => {
        if (submodule.observation || submodule.reception) {
          submodule.expand = true;
        }
      });
    });
    return element;
  }

  download() {
    window.print();
  }
  getSignature() {
    const signature = this.getModule('signature');
    const imageTag:any = document.getElementById('signature-image');
    this.rotate(signature.requiredSubmodules[0].value, 0, (resultBase64) => {
      imageTag.setAttribute('src', resultBase64);
    });
  }
  rotate(srcBase64, degrees, callback) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const image = new Image();

    image.onload = function () {
      canvas.width = degrees % 180 === 0 ? image.width : image.height;
      canvas.height = degrees % 180 === 0 ? image.height : image.width;

      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate(degrees * Math.PI / 180);
      ctx.drawImage(image, image.width / -2, image.height / -2);

      callback(canvas.toDataURL());
    };

    image.src = srcBase64;
  }
  getHeaderTitle() {
    const vehicleModule = this.getModule('vehicle');
    const vehicleData: any = {};
    vehicleModule.requiredSubmodules.forEach((data) => {
      if (data.name === 'brand') {
        vehicleData.brand = data.value;
      }
      if (data.name === 'model') {
        vehicleData.model = data.value;
      }
      if (data.name === 'version') {
        vehicleData.version = data.value;
      }
    });
    return `Tasación de ${vehicleData.brand.toUpperCase()} ${vehicleData.model.toUpperCase()} ${vehicleData.version.toUpperCase()} - ${this.inspection.licencePlate}`;
  }

}
