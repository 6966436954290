import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/user-management/login/login.component';
import { LayoutComponent } from './components/layout/layout.component';
import { InspectionsListComponent } from
    './components/inspecitons/inspections-list/inspections-list.component';
import { InspectionDetailComponent }
from './components/inspecitons/inspection-detail/inspection-detail.component';
import { UserProfileEditComponent }
from './components/user-management/user-profile-edit/user-profile-edit.component';
import { AuthGuard } from './shared';
import { InspectionMoreInformationComponent }
from './components/inspecitons/inspection-more-information/inspection-more-information.component';
import { AnalysisComponent } from './components/analysis/analysis.component';
import { InspectionDetailOnePageComponent } from
    './components/inspecitons/inspection-detail-one-page/inspection-detail-one-page.component';
import { UserListComponent } from './components/user-management/user-list/user-list.component';
import { PreviewReportComponent }
from './components/analysis/preview-report/preview-report.component';
import { InspectionGeneratorComponent } from './components/inspecitons/inspection-generator/inspection-generator.component';
import { BranchOfficesComponent } from './components/branch-offices/branch-offices.component';

const routes: Routes = [
  { path: '', component: LayoutComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard],
    children: [
      { path: '', redirectTo: 'inspectionsList', pathMatch: 'full' },
      { path: 'inspectionsList', component: InspectionsListComponent },
      { path: 'inspections/:id', component: InspectionDetailComponent },
      { path: 'inspections/:id/pictures/:picId', component: InspectionMoreInformationComponent },
      { path: 'userProfileEdit', component: UserProfileEditComponent },
      { path: 'inspectionGenerator', component: InspectionGeneratorComponent },
      { path: 'staticsAnalysis', component: AnalysisComponent },
      { path: 'users', component: UserListComponent,  data: { roles: ['superadmin'] } },
      { path: 'branchOffices', component: BranchOfficesComponent,
        data: { roles: ['superadmin'] } },
    ],
  },
  { path: 'previewReport', component: PreviewReportComponent },
  { path: 'login', component: LoginComponent },
  { path: 'inspectionsOnePage/:id', component: InspectionDetailOnePageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
