/* tslint:disable */

declare var Object: any;
export interface HistoricalInspectionInterface {
  "inspectorId"?: string;
  "pictures"?: any;
  "procedureType"?: string;
  "certificationData"?: any;
  "imageRecognitionData"?: any;
  "inspectionForm"?: any;
  "sourceApp"?: string;
  "status"?: string;
  "agentCode"?: string;
  "licencePlate"?: string;
  "clientName"?: string;
  "vehicleType"?: string;
  "transactionId"?: string;
  "requiredBy"?: string;
  "requiredByUserId"?: string;
  "branchOfficeId"?: string;
  "branchOfficeName"?: string;
  "tokenId"?: string;
  "province"?: string;
  "date"?: Date;
  "isSuccessful"?: boolean;
  "successfulMsgList"?: any;
  "failedMsgList"?: any;
  "estimates"?: any;
  "id"?: any;
}

export class HistoricalInspection implements HistoricalInspectionInterface {
  "inspectorId": string;
  "pictures": any;
  "procedureType": string;
  "certificationData": any;
  "imageRecognitionData": any;
  "inspectionForm": any;
  "sourceApp": string;
  "status": string;
  "agentCode": string;
  "licencePlate": string;
  "clientName": string;
  "vehicleType": string;
  "transactionId": string;
  "requiredBy": string;
  "requiredByUserId": string;
  "branchOfficeId": string;
  "branchOfficeName": string;
  "tokenId": string;
  "province": string;
  "date": Date;
  "isSuccessful": boolean;
  "successfulMsgList": any;
  "failedMsgList": any;
  "estimates": any;
  "id": any;
  constructor(data?: HistoricalInspectionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `HistoricalInspection`.
   */
  public static getModelName() {
    return "HistoricalInspection";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of HistoricalInspection for dynamic purposes.
  **/
  public static factory(data: HistoricalInspectionInterface): HistoricalInspection{
    return new HistoricalInspection(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'HistoricalInspection',
      plural: 'HistoricalInspections',
      path: 'HistoricalInspections',
      idName: 'id',
      properties: {
        "inspectorId": {
          name: 'inspectorId',
          type: 'string'
        },
        "pictures": {
          name: 'pictures',
          type: 'any'
        },
        "procedureType": {
          name: 'procedureType',
          type: 'string'
        },
        "certificationData": {
          name: 'certificationData',
          type: 'any'
        },
        "imageRecognitionData": {
          name: 'imageRecognitionData',
          type: 'any'
        },
        "inspectionForm": {
          name: 'inspectionForm',
          type: 'any'
        },
        "sourceApp": {
          name: 'sourceApp',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "agentCode": {
          name: 'agentCode',
          type: 'string'
        },
        "licencePlate": {
          name: 'licencePlate',
          type: 'string'
        },
        "clientName": {
          name: 'clientName',
          type: 'string'
        },
        "vehicleType": {
          name: 'vehicleType',
          type: 'string'
        },
        "transactionId": {
          name: 'transactionId',
          type: 'string'
        },
        "requiredBy": {
          name: 'requiredBy',
          type: 'string'
        },
        "requiredByUserId": {
          name: 'requiredByUserId',
          type: 'string'
        },
        "branchOfficeId": {
          name: 'branchOfficeId',
          type: 'string'
        },
        "branchOfficeName": {
          name: 'branchOfficeName',
          type: 'string'
        },
        "tokenId": {
          name: 'tokenId',
          type: 'string'
        },
        "province": {
          name: 'province',
          type: 'string'
        },
        "date": {
          name: 'date',
          type: 'Date'
        },
        "isSuccessful": {
          name: 'isSuccessful',
          type: 'boolean'
        },
        "successfulMsgList": {
          name: 'successfulMsgList',
          type: 'any'
        },
        "failedMsgList": {
          name: 'failedMsgList',
          type: 'any'
        },
        "estimates": {
          name: 'estimates',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
      }
    }
  }
}
