import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ClientApi } from '../../../shared/sdk/services/custom/index';
import { SidebarService } from '../../../shared/services/sidebar.service';
import { EventsService } from '../../../shared/services/events.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  pushRightClass = 'push-right';
  user: any;
  collapsed;
  constructor(
    public router: Router,
    public clientApi: ClientApi,
    public eventService: EventsService,
    private sidebarService: SidebarService,
  ) {

    this.router.events.subscribe((val) => {
      if (
        val instanceof NavigationEnd &&
        window.innerWidth <= 992 &&
        this.isToggled()
      ) {
        this.toggleSidebar();
      }
    });
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.sidebarService.getStatusSidebar().subscribe((status) => {
      if (status === 'close') {
        this.collapsed = true;
      } else {
        this.collapsed = false;
      }
    });
  }
  isToggled(): boolean {
    const dom: Element = document.querySelector('body');
    return dom.classList.contains(this.pushRightClass);
  }

  toggleSidebar() {
    const dom: any = document.querySelector('body');
    dom.classList.toggle(this.pushRightClass);
  }

  rltAndLtr() {
    const dom: any = document.querySelector('body');
    dom.classList.toggle('rtl');
  }

  onLoggedout() {
    localStorage.removeItem('isLoggedin');
    localStorage.removeItem('user');
    this.clientApi.logout().subscribe(
      (res: any) => {
        this.eventService.publish('clearIntervals', true);
        this.router.navigate(['login']);
      },
      (err: any) => {
        this.router.navigate(['login']);
      });
  }

}
